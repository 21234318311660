import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MantineProvider, createTheme } from '@mantine/core';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { SuppliersAndCustomersView } from './components/contacts/SuppliersAndCustomersView';
import { MessageCentreView } from './components/messageCentre/MessageCentreView';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { ConnectView } from './components/ConnectView';
import { ProtectedRoute } from './components/ProtectedRoute';
import { DashboardView } from './components/dashboard/DashboardView';
import { NotFound } from './components/notFound/NotFound';
import { SomethingWrong } from './components/somethingWrong/SomethingWrong';
import { ContactDetailView } from './components/contacts/ContactDetailView';
import { UserSettingsView } from './components/userSettings/UserSettingsView';
import { SelectTenantView } from './components/selectTenant/SelectTenantView';
import { BillsAndInvoicesView } from './components/billsAndInvoices/BillsAndInvoicesView';
import { InvoiceDetailView } from './components/billsAndInvoices/InvoiceDetailView';
import { BankTransactionDetailView } from './components/billsAndInvoices/BankTransactionDetailView';

import { PostHogProvider } from 'posthog-js/react';
import { PracticeSignUpView } from './components/practice/registration/PracticeSignUpView';
import { PracticeSignInView } from './components/practice/signIn/PracticeSignInView';
import { ClientsView } from './components/practice/clients/ClientsView';
import { PracticePasswordResetView } from './components/practice/signIn/PracticePasswordResetView';
import { ColleaguesView } from './components/practice/colleagues/ColleaguesView';
import { ScanningActivityView } from './components/scanningActivity/ScanningActivityView';
import { ActivateClientView } from './components/practice/clients/ActivateClientView';
import { ReportsView } from './components/reports/ReportsView';
import { AnalysisSummaryReportView } from './components/reports/AnalysisSummaryReportView';


dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

const posthogOpts = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://eu.i.posthog.com',
};

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <SomethingWrong />,
    children: [
      {
        path: '*',
        element: <NotFound />,
      },
      {
        element: <ProtectedRoute redirectFn={user => user && '/'} />,
        errorElement: <SomethingWrong />,
        children: [{
          path: 'connect',
          id: 'connect',
          element: <ConnectView />
        }]
      },
      {
        element: <ProtectedRoute redirectFn={(user, activeClient) => (!user && '/practice') || (activeClient && '/')} />,
        errorElement: <SomethingWrong />,
        children: [{
          path: 'select-tenant',
          id: 'select-tenant',
          element: <SelectTenantView />
        }]
      },
      {
        element: <ProtectedRoute redirectFn={(user, activeClient) => {
          if (!user) {
            // return '/connect';
            // diverting all anon to practice for now
            return '/practice';

          }
          if (!activeClient && !user.practiceId) {
            return '/select-tenant';
          }
          if (!activeClient && !!user.practiceId) {
            return '/practice';
          }
        }} />,
        errorElement: <SomethingWrong />,
        children: [{
          path: '/',
          id: 'dashboard',
          element: <DashboardView />
        }, {
          path: 'suppliers-and-customers',
          children: [{
            index: true,
            element: <SuppliersAndCustomersView />
          }, {
            path: ':contactId',
            element: <ContactDetailView />
          }]
        }, {
          path: 'bills-and-invoices',
          children: [{
            index: true,
            element: <BillsAndInvoicesView />
          }, {
            path: 'invoices/:invoiceId',
            element: <InvoiceDetailView />
          }, {
            path: 'bank-transactions/:bankTransactionId',
            element: <BankTransactionDetailView />
          }]
        }, {
          path: 'message-centre',
          element: <MessageCentreView />
        }, {
          path: 'scanning-activity',
          id: 'scanning-activity',
          element: <ScanningActivityView />
        }, {
          path: 'reports',
          id: 'reports',
          children: [{
            index: true,
            element: <ReportsView />
          }, {
            path: 'analysis-summary',
            element: <AnalysisSummaryReportView />
          }]
        }, {
          path: 'user-settings',
          element: <UserSettingsView />
        }],
      }
    ]
  },
  {
    path: 'practice',
    errorElement: <SomethingWrong />,
    children: [{
      path: 'register/*',
      id: 'register',
      element: <PracticeSignUpView />
    }, {
      path: 'login',
      id: 'login',
      element: <PracticeSignInView />
    }, {
      path: 'reset',
      id: 'reset',
      element: <PracticePasswordResetView />
    },
    {
      element: <App />,
      path: '*',
      children: [{
        element: <ProtectedRoute redirectFn={(user, activeClient) => {
          if (!user) return 'login';                    
          if (activeClient) return '/';
        }} />,
        children: [{
          path: '*',
          id: 'clients',
          element: <ClientsView />
        }, {
          path: 'colleagues',
          id: 'colleagues',
          element: <ColleaguesView />
        }]
      }, {
        element: <ProtectedRoute redirectFn={(user, activeClient) => {
          if (!user) return 'login';                    
        }} />,
        children: [
          {
            path: 'clients/:clientId',
            element: <ActivateClientView />
          }
        ]
      }]
    }]
  }
]);


const theme = createTheme({
  cursorType: 'pointer'
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY || 'phc_AfmGhI91vobAu84mN8XlZcSdEjHqk61GWppR305iHru'}
      options={posthogOpts}
    >
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={theme}>
          <RouterProvider router={router} />
        </MantineProvider>
      </QueryClientProvider>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
