import { Anchor, Badge, Flex, Space, Table, Text, rem } from '@mantine/core';
import { ContactAnalysisStateConclusionTypeEnum, Message, TenantAnalysisStats } from '../../../api/generated';
import { getAdvisorySummaryPropsForContactAnalysisConclusionType } from '../../../lib/contactAnalysis/getAdvisorySummaryPropsForContactAnalysisConclusionType';
import { Link } from 'react-router-dom';


export function InitialAnalysisCompletedTemplate({ message }: { message: Message }) {

  const { 
    conclusionTypeCounts, 
    tenantAnalysisStats
  }: {
    tenantAnalysisStats: TenantAnalysisStats;
    conclusionTypeCounts: Record<ContactAnalysisStateConclusionTypeEnum, number>;
  } = message.contentData as any;

  const advisorySummaries = Object.entries(conclusionTypeCounts).map(([type, count]) => {
    const { title, labelNoun, link } = getAdvisorySummaryPropsForContactAnalysisConclusionType(type as ContactAnalysisStateConclusionTypeEnum);
    return {
      title,
      label: `${count} ${labelNoun}${count > 1 ? 's' : ''}`,
      link
    };
  });

  return (
    <>
      <Text>
        Meysey has finished analysing your Xero data for the first time. Your message centre will contain new messages for any critical alerts and Meysey will continue to monitor for anything new that doesn't look right.
      </Text>

      <Text>
        Here's a look at what was analysed and the key findings.
      </Text>

      <Space h="lg" />

      <Text ta="center" fw="700">Meysey has analysed</Text>
      <Flex mx="auto" maw="450px" p="md" wrap="wrap" justify="center" gap="lg">

        <Badge leftSection={tenantAnalysisStats.contactCount} variant="light" size="lg">
          Xero Contacts
        </Badge>
        <Badge leftSection={tenantAnalysisStats.companyCount} variant="light" size="lg">
          Company Records
        </Badge>
        <Badge leftSection={tenantAnalysisStats.officersCount} variant="light" size="lg">
          Company Officers
        </Badge>
        {tenantAnalysisStats.invoiceCount > 0 && (
          <Badge leftSection={tenantAnalysisStats.invoiceCount} variant="light" size="lg">
            Invoices
          </Badge>
        )}
        {tenantAnalysisStats.billCount > 0 && (
          <Badge leftSection={tenantAnalysisStats.billCount} variant="light" size="lg">
            Bills
          </Badge>
        )}
        <Badge leftSection={tenantAnalysisStats.otherTransactionCount} variant="light" size="lg">
          Bank Transactions
        </Badge>
      </Flex>

      <Space h="lg" />

      <Text ta="center" fz="sm" fw={500} pb={0}>Advisory Findings</Text>
      <Text ta="center" fz="xs" pt={rem(5)}>
        These are items highlighted for your awareness as a precaution.
        <br />
        They typically don't necessitate direct action from you.
      </Text>

      {advisorySummaries.length ? (
        <Table withRowBorders={false} my="md" verticalSpacing={rem(3)} maw="450px" mx="auto" >
          <Table.Tbody>
            {advisorySummaries.map(({ title, label, link }, idx) => (
              <Table.Tr key={idx}>
                <Table.Td>
                  {title}
                </Table.Td>
                <Table.Td style={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}>
                  <Anchor component={Link} to={link} fz="sm" ta="right">{label}</Anchor>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      ) : (
        <Text ta="center">
          No advisory findings to report.
        </Text>      
      )}

      <Space h="lg" />

      <Text>
        Thank you for choosing Meysey as your partner in financial security. Together, we're on the path to a more secure and transparent financial future.
      </Text>

      <Text fw={500}>
        The Meysey Team
      </Text>


    </>
  );
}