import { Box, Progress, PasswordInput, Group, Text, Center, MantineSize } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';

import { IconCheck, IconX } from '@tabler/icons-react';

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
  return (
    <Text component="div" c={meets ? 'teal' : 'red'} mt={5} size="sm">
      <Center inline>
        {meets ? <IconCheck size="0.9rem" stroke={1.5} /> : <IconX size="0.9rem" stroke={1.5} />}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}

const passwordRequirements = [
  { re: /[0-9]/, label: 'Includes number' },
  { re: /[a-z]/, label: 'Includes lowercase letter' },
  { re: /[A-Z]/, label: 'Includes uppercase letter' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
];

export function getPasswordStrength(password: string) {
  let multiplier = password.length > 5 ? 0 : 1;

  passwordRequirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 0);
}


interface PasswordStrengthProps {
  value?: string;
  size?: MantineSize;
  defaultValue?: string;
  onChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  classNames?: Record<string, string>;
  disabled?: boolean;
}

export function PasswordStrength({ value, defaultValue, onChange, ...others }: PasswordStrengthProps) {
  
  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue,
    finalValue: '',
    onChange,
  });
  
  const strength = getPasswordStrength(_value);
  const checks = passwordRequirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(_value)} />
  ));
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: '0ms' } }}
        value={
          _value.length > 0 && index === 0 ? 100 : strength >= ((index + 1) / 4) * 100 ? 100 : 0
        }
        color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    ));

  return (
    <div>
      <PasswordInput        
        value={_value}
        onChange={(event) => handleChange(event.currentTarget.value)}
        {...others}
      />

      <Group gap={5} grow mt="xs" mb="md">
        {bars}
      </Group>

      {_value.length ? (
        <>
          <PasswordRequirement label="Has at least 6 characters" meets={_value.length > 5} />
          {checks}
        </>
      ) : null}
    </div>
  );
}