import { Button, Loader, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconMail } from '@tabler/icons-react';
import { FirebaseError } from 'firebase/app';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { Link, Navigate } from 'react-router-dom';
import { auth } from '../../../firebase';
import { GoogleIcon } from '../GoogleIcon';
import { XeroIcon } from '../XeroIcon';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { signInWithXero } from '../lib/signInWithXero';
import { showGeneralErrorNotification } from '../../../lib/helpers';


export function SelectMethod({ next }: { next: string }) {


  const [existingUser, existingUserLoading, existingUserError] = useAuthState(auth);

  const [signInWithGoogle] = useSignInWithGoogle(auth);

  const handleSignInWithXeroClick = async () => {
    try {
      await signInWithXero(auth);
    } catch (e) {
      // todo - this is fixable with some faff, not worth the time right now
      if ((e as FirebaseError).code === 'auth/account-exists-with-different-credential') {
        notifications.show({
          radius: 'md',
          color: 'red',
          title: 'Error',
          message: `An account for ${(e as FirebaseError).customData?.email} has already been created using a different method.`
        });
      } else {
        showGeneralErrorNotification();
      }
    }
  }

  if (existingUser) {
    console.log('existing user redirect');
    return <Navigate to={next} />
  }

  return (
    existingUserLoading ? (
      <Loader mt="xl" />
    ) : existingUserError ? (
      <UnableToLoadAlert />
    ) : (
      <Stack w={{ base: '100%', md: '50%' }} mt="xl" gap="md">
        <Button size="lg" variant="default" fullWidth leftSection={<IconMail size="32px" stroke="1px" />} component={Link} to='email'>Continue with email</Button>
        <Button size="lg" variant="default" fullWidth leftSection={<GoogleIcon width="32px" height="32px" />} onClick={() => signInWithGoogle()}>Continue with Google</Button>
        <Button size="lg" variant="default" fullWidth leftSection={<XeroIcon width="32px" height="32px" />} onClick={() => handleSignInWithXeroClick()}>Continue with Xero</Button>
      </Stack>
    )
  );
}