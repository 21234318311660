import { Loader, Table, Text } from '@mantine/core';

import { formatCurrency, formatDateMedium, formatDateTimeRelative } from '../../lib/formatters';
import { BankTransaction, BankTransactionTypeEnum } from '../../api/generated';

import classes from './BankTransactionsRow.module.css';
import { QueryKey } from '../../queryKeys';
import { apiClient } from '../../api/apiClient';
import { useQuery } from '@tanstack/react-query';

function isSpendType(type: BankTransactionTypeEnum) {
  return [BankTransactionTypeEnum.Spend, BankTransactionTypeEnum.SpendOverpayment, BankTransactionTypeEnum.SpendPrepayment, BankTransactionTypeEnum.SpendTransfer].includes(type);
}


type BankTransactionsRowProps = {
  bankTransaction: BankTransaction;
  onClick: React.MouseEventHandler<HTMLTableRowElement>;
}



export function BankTransactionsHeaderRow() {
  return (
    <Table.Tr key="header">
      <Table.Th>Date</Table.Th>
      <Table.Th>Acct</Table.Th>
      <Table.Th>Reference</Table.Th>
      <Table.Th>Contact</Table.Th>
      <Table.Th>Spent</Table.Th>
      <Table.Th>Received</Table.Th>
      <Table.Th>Status</Table.Th>
      <Table.Th ta="right">Fraud Analysis</Table.Th>
    </Table.Tr>
  );
}

export function BankTransactionsRow({ bankTransaction, onClick }: BankTransactionsRowProps) {

  
  const { data: bankTransactionAnalysisState, isPending: bankTransactionAnalysisStateIsPending } = useQuery({
    queryKey: [QueryKey.GetBankTransactionAnalysisState, bankTransaction.bankTransactionID],
    queryFn: async () => {
      const res = await apiClient.analysis.getBankTransactionAnalysisStateByXeroBankTransactionId(bankTransaction.bankTransactionID!);
      return res.data;
    }
  });


  return (
    <Table.Tr key={bankTransaction.bankTransactionID} onClick={onClick} className={classes.root}>

      <Table.Td className={classes.date}>
        <Text fz="sm">{formatDateMedium(bankTransaction.date!)}</Text>
      </Table.Td>
      <Table.Td className={classes.acctName}>
        <Text fz="sm">{bankTransaction.bankAccount.name}</Text>
      </Table.Td>
      <Table.Td>
        <Text fz="sm">{bankTransaction.reference}</Text>
      </Table.Td>
      <Table.Td className={classes.contactName}>
        <Text fz="sm" fw={500}>{bankTransaction?.contact?.name !== 'No Contact' && bankTransaction.contact?.name}</Text>
      </Table.Td>
      <Table.Td>
        <Text fz="sm">{isSpendType(bankTransaction.type) && formatCurrency(bankTransaction.total!, bankTransaction.currencyCode)}</Text>
      </Table.Td>
      <Table.Td>
        <Text fz="sm">{!isSpendType(bankTransaction.type) && formatCurrency(bankTransaction.total!, bankTransaction.currencyCode)}</Text>
      </Table.Td>
      <Table.Td>
        {bankTransaction.isReconciled ? (
          <Text fz="sm" fw={500} c="blue">RECONCILED</Text>
        ) : (
          <Text fz="sm" fw={500} c="dimmed">UNRECONCILED</Text>
        )}
        {/* <Text fz="sm" c={getColorForXeroPaymentStatus(payment.status)} tt="uppercase" fw={500}>{formatXeroPaymentStatus(payment.status!)}</Text> */}
      </Table.Td>

    
      <Table.Td align="right">
          {bankTransactionAnalysisStateIsPending ? (
            <Loader color="gray" type="dots" size="xs" />
          ) : (
            <div style={{ whiteSpace: 'nowrap' }}>
              <Text fz="sm">{bankTransactionAnalysisState?.lastAnalysisJobCompletedAt ? `Analysed ${formatDateTimeRelative(bankTransactionAnalysisState?.lastAnalysisJobCompletedAt)}` : 'Not yet analysed'}</Text>
            </div>
          )}
        
      </Table.Td>





      {/* <Table.Td>
        <Text fz="sm">{payment.dueDate && formatDateMedium(payment.dueDate!)}</Text>
      </Table.Td> */}



      {/* <Table.Td>
        <Text fz="sm">{formatCurrency(payment.amountDue!, payment.currencyCode)}</Text>            
      </Table.Td> */}

      {/* <Table.Td>
        <Text fz="sm" c="dimmed" fw={500} tt="uppercase">{(activeTenant && activeTenant.orgData?.baseCurrency !== payment.currencyCode) && payment.currencyCode}</Text>
      </Table.Td> */}


    </Table.Tr>
  );
}