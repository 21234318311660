import { Container, Title, Text, Paper, Center, Button, Stack, TextInput, Skeleton, Anchor } from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { Notifications } from '@mantine/notifications';
import { auth } from '../../../firebase';
import { useAuthState, useSendPasswordResetEmail } from 'react-firebase-hooks/auth';

import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { Link, Navigate } from 'react-router-dom';
import { useState } from 'react';


export function PracticePasswordResetView() {

  const [resetSent, setResetSent] = useState(false);

  const form = useForm({
    initialValues: {
      email: ''
    },
    validate: {
      email: isEmail('Invalid email'),
    }
  });

  const [authUser, authUserLoading, authUserError] = useAuthState(auth);


  const [sendPasswordResetEmail, sendPasswordResetEmailLoading] = useSendPasswordResetEmail(auth);

  if (authUser) {
    return <Navigate to='../register/verify-email' />
  }


  return (
    <>
      <Notifications position="top-right" />
      <Container my="10%">
        <Title ta="center">Welcome to Meysey</Title>
        <Text ta="center" fz="lg" mt="sm">Request a password reset for your practice account</Text>
        <Center p={0}>
          <Paper withBorder shadow="md" radius="md" p="xl" mt="xl" w={{ base: '100%', md: '40%' }}>
            {authUserLoading ? (
              <>
                <Skeleton animate h={10} mt={6} />
                <Skeleton animate h={10} mt={6} />
                <Skeleton animate h={10} mt={6} />
                <Skeleton animate h={10} mt={6} />
              </>
            ) : authUserError ? (
              <UnableToLoadAlert />
            ) : (!resetSent) ? (
              <form onSubmit={form.onSubmit(async (values) => {
                if (await sendPasswordResetEmail(values.email)) {
                  setResetSent(true);
                }
              })}>
                <Stack>
                  <TextInput
                    label="Email"
                    placeholder="Email"
                    required
                    key={form.key('email')}
                    {...form.getInputProps('email')}
                  />
                  <Button type="submit" loading={sendPasswordResetEmailLoading}>Submit</Button>
                </Stack>
              </form>
            ) : (
              <Text>Password reset requested, please check your email.</Text>
            )}

          </Paper>
        </Center>
        <Text ta="center" fz="sm" mt="lg"><Anchor inherit component={Link} to='../login'>Back to Sign In</Anchor></Text>
      </Container>
    </>
  );
}