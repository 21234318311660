import { Text, Container, Title, Space, SimpleGrid, Card, UnstyledButton } from '@mantine/core';

import classes from './ReportsView.module.css';
import { Link } from 'react-router-dom';

export function ReportsView() {
  return (

    <Container size="xl" py="xl">
      <Title order={2}>Reports</Title>
      <Text mt="md">Generate, view, and download reports on Meysey's analysis activity and findings. Choose a report from the options below.</Text>

      <Space h="xl" />

      <SimpleGrid cols={3}>

        <UnstyledButton component={Link} to="analysis-summary">
          <Card className={classes.reportCard} shadow="xs" padding="lg" radius="md">
            <Text fw={500} mb="xs">Analysis Summary Report</Text>

            <Text size="sm" c="dimmed">
              An overview of analyses conducted by Meysey on your organization's data, highlighting significant alerts identified during the review period.
            </Text>
          </Card>
        </UnstyledButton>

        {/* <UnstyledButton>
          <Card className={classes.reportCard} shadow="xs" padding="lg" radius="md">
            <Text fw={500} mb="xs">Fraud Outreach Report</Text>

            <Text size="sm" c="dimmed">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </Text>
          </Card>
        </UnstyledButton>

        <UnstyledButton>
          <Card className={classes.reportCard} shadow="xs" padding="lg" radius="md">
            <Text fw={500} mb="xs">Payroll & Expenses Report</Text>

            <Text size="sm" c="dimmed">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia.
            </Text>
          </Card>
        </UnstyledButton>

        <UnstyledButton>
          <Card className={classes.reportCard} shadow="xs" padding="lg" radius="md">
            <Text fw={500} mb="xs">Financial Trends Report</Text>

            <Text size="sm" c="dimmed">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia.
            </Text>
          </Card>
        </UnstyledButton>

        <UnstyledButton>
          <Card className={classes.reportCard} shadow="xs" padding="lg" radius="md">
            <Text fw={500} mb="xs">Report</Text>

            <Text size="sm" c="dimmed">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia.
            </Text>
          </Card>
        </UnstyledButton> */}

      </SimpleGrid>
    </Container>
  );
}

