import { Group, Loader, Table, Text } from '@mantine/core';
import { Invoice } from '../../api/generated';

import { formatCurrency, formatDateMedium, formatDateTimeRelative, formatXeroInvoiceStatus } from '../../lib/formatters';
import { useContext } from 'react';
import { UserContext } from '../../userContext';

import classes from './InvoicesRow.module.css';
import { getColorForXeroInvoiceStatus } from './helpers';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../queryKeys';
import { apiClient } from '../../api/apiClient';

export type InvoicesRowProps = {
  invoice: Invoice;
  onClick: React.MouseEventHandler<HTMLTableRowElement>;
}


export function InvoicesHeaderRow() {
  return (
    <Table.Tr key="header">
      <Table.Th>Date</Table.Th>
      <Table.Th>Reference</Table.Th>
      <Table.Th>Contact</Table.Th>
      <Table.Th>Value</Table.Th>
      <Table.Th>Status</Table.Th>
      <Table.Th ta="right">Fraud Analysis</Table.Th>
    </Table.Tr>
  );
}

export function InvoicesRow({ invoice, onClick }: InvoicesRowProps) {

  const { activeClient } = useContext(UserContext);

  const { data: invoiceAnalysisState, isPending: invoiceAnalysisStateIsPending } = useQuery({
    queryKey: [QueryKey.GetInvoiceAnalysisState, invoice.invoiceID],
    queryFn: async () => {
      const res = await apiClient.analysis.getInvoiceAnalysisStateByXeroInvoiceId(invoice.invoiceID!);
      return res.data;
    }
  });

  return (
    <Table.Tr key={invoice.invoiceID} className={classes.root} onClick={onClick}>

      <Table.Td className={classes.date}>
        <Text fz="sm">{formatDateMedium(invoice.date!)}</Text>
      </Table.Td>
      <Table.Td>
        <Text fz="sm">{invoice.invoiceNumber}</Text>
      </Table.Td>
      <Table.Td className={classes.contactName}>
        <Text fz="sm" fw={500}>{invoice.contact?.name !== 'No Contact' && invoice.contact?.name}</Text>
      </Table.Td>
      <Table.Td>
        <Text fz="sm">{formatCurrency(invoice.total!, invoice.currencyCode)}</Text>
      </Table.Td>
      <Table.Td>
        <Text fz="sm" c={getColorForXeroInvoiceStatus(invoice.status)} tt="uppercase" fw={500}>{formatXeroInvoiceStatus(invoice.status!)}</Text>
      </Table.Td>

      <Table.Td align="right">
          {invoiceAnalysisStateIsPending ? (
            <Loader color="gray" type="dots" size="xs" />
          ) : (
            <div style={{ whiteSpace: 'nowrap' }}>
              <Text fz="sm">{invoiceAnalysisState?.lastAnalysisJobCompletedAt ? `Analysed ${formatDateTimeRelative(invoiceAnalysisState?.lastAnalysisJobCompletedAt)}` : 'Not yet analysed'}</Text>
            </div>
          )}
        
      </Table.Td>





      {/* <Table.Td>
        <Text fz="sm">{invoice.dueDate && formatDateMedium(invoice.dueDate!)}</Text>
      </Table.Td> */}



      {/* <Table.Td>
        <Text fz="sm">{formatCurrency(invoice.amountDue!, invoice.currencyCode)}</Text>            
      </Table.Td> */}

      {/* <Table.Td>
        <Text fz="sm" c="dimmed" fw={500} tt="uppercase">{(activeClient && activeClient.orgData?.baseCurrency !== invoice.currencyCode) && invoice.currencyCode}</Text>
      </Table.Td> */}


    </Table.Tr>
  );
}