import { Text, Container, Title, Space, Select, Group, Button, Card, Stack, Paper, Table, Box, Flex, Anchor, ScrollArea, Loader } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { usePlatformDefaultCtrlSize } from '../../hooks/usePlatformDefaultCtrlSize';
import { DatePickerInput, DateValue } from '@mantine/dates';
import { IconDownload, IconTrendingUp2 } from '@tabler/icons-react';
import { calculateReportingPeriodFromOption, ReportingPeriodOption, reportingPeriodOptionLabels } from './lib/reportingPeriodOption';
import { ReportingPeriod } from '../../lib/reporting/reportingPeriodCalculator';
import { apiClient } from '../../api/apiClient';
import { UserContext } from '../../userContext';
import { QueryKey } from '../../queryKeys';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import classes from './AnalysisSummaryReportView.module.css';
import { formatCurrency, formatCurrencyNoSymbol, formatDateForFileName, formatDateMedium, formatDateShort, formatDateTimeShort, formatNumber } from '../../lib/formatters';
import { AreaChart, BubbleChart, PieChart } from '@mantine/charts';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Link, useSearchParams } from 'react-router-dom';
import { AnalysisSummaryReportAnalysisActivity, AnalysisSummaryReportAnalysisLogItem, AnalysisSummaryReportAnalysisLogItemBankTransactionTypeEnum, AnalysisSummaryReportAnalysisLogItemInvoiceTypeEnum, AnalysisSummaryReportAnalysisLogItemItemTypeEnum, AnalysisSummaryReportNoteworthyTradingPartners, AnalysisSummaryReportUserActivity, Message, NoteworthyTradingPartnerTradingVolume, NoteworthyTradingPartnerTransaction } from '../../api/generated';
import { sum, uniq } from 'lodash';
import { Legend } from 'recharts';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
// import { useIsPrint } from '../../hooks/useIsPrint';


function StatCard({ title, value, large, children }: { title: string, value: number | undefined, large?: boolean, children?: React.ReactNode }) {
  return (
    <Card className={classes.statCard} shadow="xs" padding={large ? 'xl' : 'lg'} radius="sm">
      {value === undefined ? (
        <Loader />
      ) : (
        <Text fw={700} fz={large ? 'h2' : 'h3'}>{formatNumber(value)}</Text>
      )}
      <Text fw={500}>{title}</Text>
      {children}
    </Card>
  );
}

function AnalysisHeadlineStats({
  analysisActivity
}: {
  analysisActivity: AnalysisSummaryReportAnalysisActivity
}) {
  return (
    <Flex direction={{ base: "column", sm: "row" }} gap="md" align="flex-start" className={classes.statCardContainer}>

      <Flex direction="inherit" gap="inherit" align="center">
        <StatCard large title="Total Items Analysed" value={analysisActivity.totalUniqueItemsAnalysedCount} />
        <Box className={classes.headlineStatArrow}>
          <IconTrendingUp2 color="var(--mantine-color-gray-5)" size="3rem" stroke="1" />
        </Box>
      </Flex>


      <Group className={classes.statCardContainer}>
        <StatCard large title="Critical Alerts" value={analysisActivity.totalCriticalAlertsCount} />
        <StatCard large title="Important Advisories" value={analysisActivity.totalAdvisoriesCount} />
      </Group>
    </Flex>
  );
}

function AnalysisBreakdownStats({
  analysisActivity
}: {
  analysisActivity: AnalysisSummaryReportAnalysisActivity
}) {
  return (
    <Flex direction={{ base: "column", md: "row" }} gap={{ base: "xl", md: "md" }} className={`${classes.statCardContainer} ${classes.statCardContainerFullWidth}`}>

      <Stack flex="1" className={classes.statCardStack}>
        <Text ta="left">Financial Documents</Text>
        <Group py="lg" className={classes.statCardContainer}>

          <StatCard title="Bills" value={analysisActivity.uniquePayableInvoicesAnalysedCount}>
            <Text mt="sm"><strong>{analysisActivity.uniquePayableInvoiceAttachmentsAnalysedCount}</strong> with attachments</Text>
          </StatCard>
          <StatCard title="Invoices" value={analysisActivity.uniqueReceivableInvoicesAnalysedCount}>
            <Text mt="sm"><strong>{analysisActivity.uniqueReceivableInvoiceAttachmentsAnalysedCount}</strong> with attachments</Text>
          </StatCard>
          {/* <StatCard title="Bank Transactions" value={analysisActivity.uniqueBankTransactionsAnalysedCount}>
            <Text mt="sm"><strong>{analysisActivity.uniqueBankTransactionAttachmentsAnalysedCount}</strong> with attachments</Text>
          </StatCard> */}

        </Group>
      </Stack>

      <Stack flex="1" className={classes.statCardStack}>
        <Text ta="left">Suppliers, Customers & Other Contacts</Text>

        <Flex direction={{ base: 'column', sm: 'row' }} py="lg" gap="md" align="flex-start" className={classes.statCardContainer}>

          <Flex direction="inherit" gap="inherit" align="center">
            <Card shadow="xs" padding={'lg'} radius="sm">
              <Text fw={700} fz={'h3'}>{analysisActivity.uniqueContactsAnalysedCount}</Text>
              <Text fw={500}>Contacts</Text>
              <Text mt="sm" fz="xs"><strong>{analysisActivity.uniqueCustomersAnalysedCount}</strong> Customers <strong>{analysisActivity.uniqueSuppliersAnalysedCount}</strong> Suppliers</Text>
              <Text fz="xs"><strong>{analysisActivity.uniqueUncategorisedContactAnalysedCount}</strong> Uncategorised</Text>
            </Card>
            <Box className={classes.breakdownStatArrow}><IconTrendingUp2 color="var(--mantine-color-gray-5)" size="2rem" stroke="1" /></Box>
          </Flex>

          <Group gap="inherit">
            <StatCard title="Companies" value={analysisActivity.contactsAnalysedCompanyMatchesCount}>
              <Text fz="xs">Monitored at<br />Companies House <br />& other registries</Text>
            </StatCard>
            <StatCard title="Company Officers" value={analysisActivity.contactAnalysedCompanyMatchesOfficerCount}>
              <Text fz="xs">Monitored at<br />Companies House <br />& other registries</Text>
            </StatCard>
          </Group>

        </Flex>

      </Stack>

    </Flex>
  );
}

export function AnalysisBreakdownCharts({
  isMobile,
  analysisActivity
}: {
  isMobile: boolean,
  analysisActivity: AnalysisSummaryReportAnalysisActivity
}) {

  const histogramData = analysisActivity.uniqueItemsHistogram.map(h => ({
    date: formatDateMedium(h.from),
    Count: h.count
  }));

  return (
    <Flex direction={{ base: "column", md: "row" }} gap={{ base: "xl", md: "md" }} align="stretch" wrap="wrap" className={classes.chartContainer}>

      <Paper shadow="xs" radius="md" p="md" pb="0">
        <Stack justify="space-between" h="100%" align="stretch">
          <Text fz="lg" fw={500}>Analysis Activity</Text>
          {!histogramData.length ? (
            <Stack w={isMobile ? '100%' : 500} h="100%" justify="center">
              <Text ta="center" c="dimmed">No Data</Text>
            </Stack>
          ) : (
            <AreaChart
              ml={-20}
              pb="1rem"
              w={{ base: "100%", md: 500 }}
              h={isMobile ? 140 : 240}
              gridAxis='none'
              withDots={false}
              data={histogramData}
              dataKey="date"
              series={[
                { name: 'Count', color: 'violet.6' }
              ]}
              withYAxis={true}
              curveType='linear'
            />
          )}
        </Stack>
      </Paper>

      <Paper shadow="xs" radius="md" p="md" pb="0">
        <Stack align="center" gap="0" h="100%">
          <Text w="100%" ta="left" fz="lg" fw={500}>Analysis Value £</Text>
          <Space h="sm" />

          {
            !analysisActivity.uniqueReceivableInvoicesAnalysedValue &&
              !analysisActivity.uniquePayableInvoicesAnalysedValue &&
              !analysisActivity.uniqueBankTransactionsAnalysedValue ? (
              <Stack w={isMobile ? '100%' : 500} h="100%" justify="center">
                <Text ta="center" c="dimmed">No Data</Text>
              </Stack>
            ) : (
              <PieChart
                // pieProps={{
                //   dataKey: 'value',
                //   cx: isMobile ? 180 : 220,
                //   label: ({ x, y, cx, cy, percent, value, name }) => (
                //     <text
                //       x={x}
                //       y={y}
                //       cx={cx}
                //       cy={cy}
                //       textAnchor={x > cx ? 'start' : 'end'}
                //       fill="var(--chart-labels-color, var(--mantine-color-dimmed))"
                //       fontFamily="var(--mantine-font-family)"
                //       fontWeight={500}
                //       fontSize={14}
                //     >
                //       <tspan x={x}>{name} - {formatCurrency(value)}</tspan>
                //     </text>
                //   )
                // }}
                valueFormatter={formatCurrency}
                withLabels
                paddingAngle={10}
                size={isMobile ? 90 : 150}
                h={isMobile ? 140 : 240}
                w={isMobile ? '100%' : 500}
                data={[{
                  color: 'pink',
                  name: 'Bills',
                  value: analysisActivity.uniqueReceivableInvoicesAnalysedValue
                }, {
                  color: 'green.6',
                  name: 'Invoices',
                  value: analysisActivity.uniquePayableInvoicesAnalysedValue
                // }, {
                //   color: 'blue.6',
                //   name: 'Bank Transactions',
                //   value: analysisActivity.uniqueBankTransactionsAnalysedValue
                }]}
              >
                <Legend
                  layout="vertical"
                  align="center"
                  verticalAlign="bottom"
                />
              </PieChart>
            )}
          <Space h="sm" />

        </Stack>
      </Paper>

    </Flex>

  );
}

function TradingPartnersTable({
  tradingPartnersTradingVolume
}: {
  tradingPartnersTradingVolume: NoteworthyTradingPartnerTradingVolume[]
}) {

  const rows = tradingPartnersTradingVolume.map(tp => {
    return (
      <Table.Tr key={tp.contactId}>
        <Table.Td>
          <Anchor inherit component={Link} to={`/suppliers-and-customers/${tp.contactId}`}>{tp.contactName}</Anchor>
        </Table.Td>

        <Table.Td>
          {formatCurrencyNoSymbol(tp.spend)}
        </Table.Td>

        <Table.Td>
          {formatCurrencyNoSymbol(tp.receive)}
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Table className={classes.statsTable} withRowBorders>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            Contact
          </Table.Th>
          <Table.Th>
            Spend
          </Table.Th>
          <Table.Th>
            Receive
          </Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {rows.length ? rows : <Table.Tr><Table.Td colSpan={3} align="center" h="100px" c="dimmed">No Data</Table.Td></Table.Tr>}
      </Table.Tbody>
    </Table>
  );
}

function generateDescriptionForTransaction(tp: NoteworthyTradingPartnerTransaction) {
  return `${tp.invoiceNumber || tp.ref || formatDateShort(tp.date)}`;
}

function TransactionTable({
  transactions
}: {
  transactions: NoteworthyTradingPartnerTransaction[]
}) {

  const rows = transactions.map(tp => {
    return (
      <Table.Tr key={tp.extId}>
        <Table.Td>
          <Anchor inherit component={Link} to={`/suppliers-and-customers/${tp.extContactId}`}>{tp.contactName}</Anchor>
        </Table.Td>

        <Table.Td>
          <Anchor inherit component={Link} to={`/bills-and-invoices/${tp.entity === 'NormalizedInvoice' ? 'invoices' : 'bank-transactions'}/${tp.extId}`}>{generateDescriptionForTransaction(tp)}</Anchor>
        </Table.Td>

        <Table.Td>
          {formatCurrencyNoSymbol(tp.total)}
        </Table.Td>
      </Table.Tr>
    )
  });

  return (
    <Table className={classes.statsTable}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Contact</Table.Th>
          <Table.Th>Document</Table.Th>
          <Table.Th>Value</Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {rows.length ? rows : <Table.Tr><Table.Td colSpan={3} align="center" h="100px" c="dimmed">No Data</Table.Td></Table.Tr>}
      </Table.Tbody>
    </Table>
  );
}

function UserActivityTable({
  userActivity
}: {
  userActivity: AnalysisSummaryReportUserActivity
}) {

  const rows = userActivity.userActivityBreakdown.map(x => {
    return (
      <Table.Tr key={x.userName}>
        <Table.Td>
          {x.userName}
        </Table.Td>

        <Table.Td>
          {x.billsCreatedCount > 0 ? (
            <>
              {x.billsCreatedCount}
              &nbsp;
              <IconTrendingUp2 size="0.5rem" />
              &nbsp;
              {formatCurrencyNoSymbol(x.billsCreatedValue)}
            </>
          ) : (
            <>-</>
          )}
        </Table.Td>

        <Table.Td>
          {x.billsApprovedCount > 0 ? (
            <>
              {x.billsApprovedCount}
              &nbsp;
              <IconTrendingUp2 size="0.5rem" />
              &nbsp;
              {formatCurrencyNoSymbol(x.billsApprovedValue)}
            </>
          ) : (
            <>-</>
          )}
        </Table.Td>

        <Table.Td>
          {x.invoicesCreatedCount > 0 ? (
            <>
              {x.invoicesCreatedCount}
              &nbsp;
              <IconTrendingUp2 size="0.5rem" />
              &nbsp;
              {formatCurrencyNoSymbol(x.invoicesCreatedValue)}
            </>
          ) : (
            <>-</>
          )}
        </Table.Td>

        <Table.Td>
          {x.invoicesApprovedCount > 0 ? (
            <>
              {x.invoicesApprovedCount}
              &nbsp;
              <IconTrendingUp2 size="0.5rem" />
              &nbsp;
              {formatCurrencyNoSymbol(x.invoicesApprovedValue)}
            </>
          ) : (
            <>-</>
          )}
        </Table.Td>

        {/* <Table.Td>
          {x.spendBankTransactionsReconciledCount + x.receiveBankTransactionsReconciledCount > 0 ? (
            <>
              {x.spendBankTransactionsReconciledCount + x.receiveBankTransactionsReconciledCount}
              &nbsp;
              <IconTrendingUp2 size="0.5rem" />
              &nbsp;
              {formatCurrencyNoSymbol(x.spendBankTransactionsReconciledValue + x.receiveBankTransactionsReconciledValue)}
            </>
          ) : (
            <>-</>
          )}
        </Table.Td> */}
      </Table.Tr>
    );

  });

  return (
    <Table className={`${classes.statsTable} ${classes.userActivityTable}`}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>User</Table.Th>
          <Table.Th>Bills Raised</Table.Th>
          <Table.Th>Bills Approved</Table.Th>
          <Table.Th>Invoices Raised</Table.Th>
          <Table.Th>Invoices Approved</Table.Th>
          {/* <Table.Th>Reconciled</Table.Th> */}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {rows.length ? rows : <Table.Tr><Table.Td colSpan={5} align="center" h="100px" c="dimmed">No Data</Table.Td></Table.Tr>}
      </Table.Tbody>
    </Table>
  );
}


const pieColors = ['violet.6', 'cyan.6', 'green.6', 'yellow.6', 'orange.6', 'red.6'];

function UserActivityApprovalCharts({
  isMobile,
  userActivity
}: {
  isMobile: boolean,
  userActivity: AnalysisSummaryReportUserActivity
}) {

  const billsApprovedData = uniq(userActivity.userActivityBreakdown.map(x => x.userName)).map((userName, idx) => ({
    name: userName,
    color: pieColors[idx % (pieColors.length - 1)],
    value: sum(userActivity.userActivityBreakdown.filter(x => x.userName === userName).map(x => x.billsApprovedValue))
  })).filter(x => x.value > 0);

  const invoicesApprovedData = uniq(userActivity.userActivityBreakdown.map(x => x.userName)).map((userName, idx) => ({
    name: userName,
    color: pieColors[idx % (pieColors.length - 1)],
    value: sum(userActivity.userActivityBreakdown.filter(x => x.userName === userName).map(x => x.invoicesApprovedValue))
  })).filter(x => x.value > 0);

  // const bankTransactionsReconciledData = uniq(userActivity.userActivityBreakdown.map(x => x.userName)).map((userName, idx) => ({
  //   name: userName,
  //   color: pieColors[idx % (pieColors.length - 1)],
  //   value: sum(userActivity.userActivityBreakdown.filter(x => x.userName === userName).map(x => x.spendBankTransactionsReconciledValue + x.receiveBankTransactionsReconciledValue))
  // })).filter(x => x.value > 0);

  return (
    <Group justify="center" gap="lg" className={classes.unbreakableContainer}>
      <Stack align='center'>
        <Text fz="sm" fw="500">Bills Approved</Text>
        {!billsApprovedData.length ? (
          <Stack w={isMobile ? '100%' : 500} h="240px" justify="center">
            <Text ta="center" c="dimmed">No Data</Text>
          </Stack>
        ) : (
          <PieChart
            // style={{ overflow: 'visible' }}
            // // withLabels
            // // withLabelsLine={false}                  
            // pieProps={{
            //   dataKey: 'value',
            //   cx: isMobile ? 200 : 220,
            //   label: ({ x, y, cx, cy, percent, value, name }) => (
            //     <text
            //       x={x}
            //       y={y}
            //       cx={cx}
            //       cy={cy}
            //       textAnchor={x > cx ? 'start' : 'end'}
            //       fill="var(--chart-labels-color, var(--mantine-color-dimmed))"
            //       fontFamily="var(--mantine-font-family)"
            //       fontWeight={500}
            //       fontSize={14}
            //     >
            //       <tspan x={x}>{name} - {formatCurrency(value)}</tspan>
            //     </text>
            //   )
            // }}
            withLabels
            valueFormatter={formatCurrency}
            size={isMobile ? 90 : 150}
            h={isMobile ? 140 : 300}
            w={isMobile ? '100%' : 500}
            data={billsApprovedData}
          >
            <Legend
              layout="vertical"
              align="center"
              verticalAlign="bottom"
            />
          </PieChart>
        )}
      </Stack>
      <Stack align="center">
        <Text fz="sm" fw="500">Invoices Approved</Text>
        {!invoicesApprovedData.length ? (

          <Stack w={isMobile ? '100%' : 500} h="240px" justify="center">
            <Text ta="center" c="dimmed">No Data</Text>
          </Stack>

        ) : (
          <PieChart
            // style={{ overflow: 'visible' }}
            // pieProps={{
            //   dataKey: 'value',
            //   cx: isMobile ? 200 : 220,
            //   label: ({ x, y, cx, cy, percent, value, name }) => (
            //     <text
            //       x={x}
            //       y={y}
            //       cx={cx}
            //       cy={cy}
            //       textAnchor={x > cx ? 'start' : 'end'}
            //       fill="var(--chart-labels-color, var(--mantine-color-dimmed))"
            //       fontFamily="var(--mantine-font-family)"
            //       fontWeight={500}
            //       fontSize={14}
            //     >
            //       <tspan x={x}>{name} - {formatCurrency(value)}</tspan>
            //     </text>
            //   )
            // }}
            withLabels
            valueFormatter={formatCurrency}
            size={isMobile ? 90 : 150}
            h={isMobile ? 140 : 300}
            w={isMobile ? "100%" : 500}
            data={invoicesApprovedData}
          >
            <Legend
              layout="vertical"
              align="center"
              verticalAlign="bottom"
            />
          </PieChart>
        )}
      </Stack>
      {/* <Stack align="center" className={classes.unbreakableContainer}>
        <Text fz="sm" fw="500">Transactions Reconciled</Text>
        {!bankTransactionsReconciledData.length ? (

          <Stack w={isMobile ? '100%' : 500} h="240px" justify="center">
            <Text ta="center" c="dimmed">No Data</Text>
          </Stack>

        ) : (
          <PieChart
            withLabels
            // pieProps={{
            //   dataKey: 'value',
            //   cx: isMobile ? 200 : 220,
            //   label: ({ x, y, cx, cy, percent, value, name }) => (
            //     <text
            //       x={x}
            //       y={y}
            //       cx={cx}
            //       cy={cy}
            //       textAnchor={x > cx ? 'start' : 'end'}
            //       fill="var(--chart-labels-color, var(--mantine-color-dimmed))"
            //       fontFamily="var(--mantine-font-family)"
            //       fontWeight={500}
            //       fontSize={14}
            //     >
            //       <tspan x={x}>{name} - {formatCurrency(value)}</tspan>
            //     </text>
            //   )
            // }}
            valueFormatter={formatCurrency}
            size={isMobile ? 90 : 150}
            h={isMobile ? 140 : 300}
            w={isMobile ? "100%" : 500}
            data={bankTransactionsReconciledData}
          >
            <Legend
              layout="vertical"
              align="center"
              verticalAlign="bottom"
            />
          </PieChart>
        )}
      </Stack> */}
    </Group>
  );
}

function UserActivityBubbleCharts({
  userActivity,
  isPrint
}: {
  userActivity: AnalysisSummaryReportUserActivity,
  isPrint: boolean
}) {

  const hourOfDayData = userActivity.hourOfDayBubbleChart.map(h => ({
    hour: `${h.hour}:00`,
    index: h.count > 0 ? 1 : 0,
    value: h.count
  }));

  const dayOfMonthData = userActivity.dayOfMonthBubbleChart.map(h => ({
    day: h.day,
    index: h.count > 0 ? 1 : 0,
    value: h.count
  }));

  return (
    <Stack px={{ base: 'xs', md: '10px' }} pb="lg" className={classes.unbreakableContainer}>
      <Text fz="sm" fw="500" w="100%" ta="center">Activity / Hour of Day</Text>
      <BubbleChart
        mx="auto"
        mt="xs"
        h={60}
        w={isPrint ? 1000 : undefined}
        data={hourOfDayData}
        range={[16, 500]}
        // label="Activity/hour
        color="lime.6"
        dataKey={{ x: 'hour', y: 'index', z: 'value' }}
      />
      <Space h="sm" />
      <Text fz="sm" fw="500" w="100%" ta="center">Activity / Day of Month</Text>
      <BubbleChart
        mt="xs"
        mx="auto"
        w={isPrint ? 1000 : undefined}
        h={60}
        data={dayOfMonthData}
        range={[16, 500]}
        // label="Activity/day"
        color="blue.6"
        dataKey={{ x: 'day', y: 'index', z: 'value' }}
      />
    </Stack>
  );
}

function AlertsAndAdvisoriesTable({
  alertMessages
}: {
  alertMessages: Message[]
}) {

  // const rows = alertsAndAdvisories.map((item, index) => {

  //   let subject;
  //   let description;

  //   if (item.normalizedInvoice) {
  //     subject = `Invoice ${item.normalizedInvoice.invoiceNumber} ${item.normalizedInvoice.reference} dated ${formatDateShort(item.normalizedInvoice.date)}`;
  //     // description = getTitleForInvoiceAnalysisConclusion()
  //   }

  //   if (item.normalizedBankTransaction) {
  //     const isSpend = item.normalizedBankTransaction.type === NormalizedBankTransactionTypeEnum.Spend || item.normalizedBankTransaction.type === NormalizedBankTransactionTypeEnum.SpendTransfer;
  //     subject = `${isSpend ? 'Spend' : 'Receive'} Transaction dated ${formatDateShort(item.normalizedBankTransaction.date)}`;
  //   }

  //   if (item.normalizedContact) {
  //     subject = `Contact: ${item.normalizedContact.contactName}`;
  //   }

  //   return (
  //     <Table.Tr key={index}>
  //       <Table.Td>{formatDateShort(item.created)}</Table.Td>
  //       <Table.Td>{subject}</Table.Td>
  //       <Table.Td></Table.Td>
  //       <Table.Td>Important</Table.Td>
  //     </Table.Tr>
  //   );
  // });


  const rows = alertMessages.map((item, index) => {

    return (
      <Table.Tr key={index}>
        <Table.Td>{formatDateShort(item.created)}</Table.Td>
        <Table.Td>{item.contentPreheading}</Table.Td>
        <Table.Td>{item.severity}</Table.Td>
      </Table.Tr>
    );
  });


  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Date</Table.Th>
          <Table.Th>Description</Table.Th>
          <Table.Th>Severity</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {rows.length ? rows : <Table.Tr><Table.Td colSpan={3} align="center" h="100px" c="dimmed">No Data</Table.Td></Table.Tr>}
      </Table.Tbody>
    </Table>
  );
}

function generateDescriptionForAnalysisLogItem(item: AnalysisSummaryReportAnalysisLogItem) {

  switch (item.itemType) {
    case AnalysisSummaryReportAnalysisLogItemItemTypeEnum.Contact:
      return `Contact: ${item.contactName}`;
    case AnalysisSummaryReportAnalysisLogItemItemTypeEnum.Invoice:
      const noun = item.invoiceType === AnalysisSummaryReportAnalysisLogItemInvoiceTypeEnum.Payable ? 'Bill' : 'Invoice';
      return `${noun} ${item.invoiceNumber || item.reference} for ${formatCurrency(item.total!)} dated ${formatDateShort(item.itemDate!)}`;
    case AnalysisSummaryReportAnalysisLogItemItemTypeEnum.BankTransaction:
      const isSpend = item.bankTransactionType === AnalysisSummaryReportAnalysisLogItemBankTransactionTypeEnum.Spend;
      return `${isSpend ? 'Spend' : 'Receive'} Transaction for ${formatCurrency(item.total!)} dated ${formatDateShort(item.itemDate!)}`;
    case AnalysisSummaryReportAnalysisLogItemItemTypeEnum.Attachment:
      return `Attachment: ${item.attachmentFileName}`;
  }
}

function AnalysisLogTable({
  analysisLog
}: {
  analysisLog: AnalysisSummaryReportAnalysisLogItem[]
}) {

  const rows = analysisLog.map((a, idx) => (
    <Table.Tr key={idx}>
      <Table.Td>{formatDateTimeShort(a.analysisDate)}</Table.Td>
      <Table.Td>{generateDescriptionForAnalysisLogItem(a)}</Table.Td>
      <Table.Td>{a.alerts}</Table.Td>
      <Table.Td>{a.advisories}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Date</Table.Th>
          <Table.Th>Description</Table.Th>
          <Table.Th>Alerts</Table.Th>
          <Table.Th>Advisories</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {rows.length ? rows : <Table.Tr><Table.Td colSpan={4} align="center" h="100px" c="dimmed">No Data</Table.Td></Table.Tr>}
      </Table.Tbody>
    </Table>
  );
}

function ReportBody({
  reportingPeriod,
  analysisActivity,
  analysisActivityIsError,
  noteworthyTradingPartners,
  noteworthyTradingPartnersIsError,
  userActivity,
  userActivityIsError,
  analysisLog,
  analysisLogIsError,
  alertMessages,
  alertMessagesIsError,
  isMobile,
  // isPrint
}: {
  reportingPeriod: ReportingPeriod,
  analysisActivity: AnalysisSummaryReportAnalysisActivity | undefined,
  analysisActivityIsError: boolean,
  noteworthyTradingPartners: AnalysisSummaryReportNoteworthyTradingPartners | undefined,
  noteworthyTradingPartnersIsError: boolean,
  userActivity: AnalysisSummaryReportUserActivity | undefined,
  userActivityIsError: boolean,
  analysisLog: AnalysisSummaryReportAnalysisLogItem[] | undefined,
  analysisLogIsError: boolean,
  alertMessages: Message[] | undefined,
  alertMessagesIsError: boolean,
  isMobile: boolean,
  // isPrint: boolean
}) {
  return (
    <>
      <Title order={3}>Analysis Activity {formatDateMedium(reportingPeriod?.startDateTime!)} - {formatDateMedium(reportingPeriod?.endDateTime!)}</Title>
      <Text mt="md">
        Analysis activity covers all tasks and data changes including contacts, invoices, bills, bank transactions, and reconciliations.
      </Text>
      <Space h="xl" />
      <Space h="md" />

      {analysisActivityIsError ? (
        <UnableToLoadAlert />
      ) : !analysisActivity ? (
        <Loader />
      ) : (
        <AnalysisHeadlineStats analysisActivity={analysisActivity} />
      )}


      <Space h="xl" />
      <Space h="md" />

      <Title order={4}>Breakdown</Title>
      <Space h="xl" />

      {analysisActivityIsError ? (
        <UnableToLoadAlert />
      ) : !analysisActivity ? (
        <Loader />
      ) : (
        <>
          <AnalysisBreakdownStats analysisActivity={analysisActivity} />

          <Space h="xl" />
          <AnalysisBreakdownCharts isMobile={!!isMobile} analysisActivity={analysisActivity} />
        </>
      )}

      <Space h="xl" />
      <Space h="xl" />

      <Title order={4}>Noteworthy</Title>

      <Space h="xl" />

      <Flex gap="md" wrap="wrap" direction={{ base: "column", md: "row" }} className={classes.statsTableContainer}>

        <Paper shadow="xs" radius="md" p="lg" flex="1">
          <Stack>
            <Text fz="lg" fw={500}>New Trading Partners</Text>

            {
              noteworthyTradingPartnersIsError ? (
                <UnableToLoadAlert />
              ) : !noteworthyTradingPartners ? (
                <Loader />
              ) : (
                <TradingPartnersTable tradingPartnersTradingVolume={noteworthyTradingPartners.newContactsTradingVolume} />
              )
            }

          </Stack>
        </Paper>

        <Paper shadow="xs" radius="md" p="lg" flex="1">
          <Stack>
            <Text fz="lg" fw={500}>Significant Trading Partners</Text>
            {
              noteworthyTradingPartnersIsError ? (
                <UnableToLoadAlert />
              ) : !noteworthyTradingPartners ? (
                <Loader />
              ) : (
                <TradingPartnersTable tradingPartnersTradingVolume={noteworthyTradingPartners.contactsTradingVolume} />
              )
            }
          </Stack>
        </Paper>


        <Paper shadow="xs" radius="md" p="lg" flex="1.2" className={classes.unbreakableContainer}>
          <Stack>
            <Text fz="lg" fw={500}>Largest Transactions</Text>

            {noteworthyTradingPartnersIsError ? (
              <UnableToLoadAlert />
            ) : !noteworthyTradingPartners ? (
              <Loader />
            ) : (
              <TransactionTable transactions={noteworthyTradingPartners.transactions} />
            )
            }

          </Stack>
        </Paper>


      </Flex>

      <Space h="xl" />



      <Paper shadow="xs" radius="md" p="lg" w="100%">
        <Stack>
          <Text fz="lg" fw={500}>User Activity - Breakdown</Text>          
          <ScrollArea type="auto" pb="md">
            {userActivityIsError ? (
              <UnableToLoadAlert />
            ) : !userActivity ? (
              <Loader />
            ) : (
              <UserActivityTable userActivity={userActivity} />
            )}
          </ScrollArea>

        </Stack>

      </Paper>

      <Space h="xl" />

      <Paper shadow="xs" radius="md" p="lg">
        <Stack>
          <Text fz="lg" fw={500}>User Activity - Visualised</Text>
          <Text mb="lg" fz="sm">An outline of administration timings and trends which can be used to help identify unusual behaviours. For further details can be found in the reconciliation report within Xero.</Text>
          {userActivityIsError ? (
            <UnableToLoadAlert />
          ) : !userActivity ? (
            <Loader />
          ) : (
            <>
              <UserActivityApprovalCharts userActivity={userActivity} isMobile={!!isMobile} />
              <Space h="sm" />
              <UserActivityBubbleCharts isPrint userActivity={userActivity} />
            </>
          )}

        </Stack>
      </Paper>




      <Space h="xl" />

      <Title order={4}>Alerts</Title>
      <Text mt="md">Notifications raised during the reporting period.</Text>
      <Space h="xl" />
      <Paper flex="1" shadow="xs" radius="md" p="lg">
        <ScrollArea type="auto" pb="md">
          {alertMessagesIsError ? (
            <UnableToLoadAlert />
          ) : !alertMessages ? (
            <Loader />
          ) : (
            <AlertsAndAdvisoriesTable alertMessages={alertMessages} />
          )}
        </ScrollArea>
      </Paper>

      <Space h="xl" />

      {/* revisit https://stackoverflow.com/questions/9238868/how-do-i-avoid-a-page-break-immediately-after-a-heading */}
      <Title order={4}>Analysis Log</Title>
      <Text mt="md">A detailed log of analysis performed during the reporting period.</Text>
      <Space h="xl" />
      <Paper flex="1" shadow="xs" radius="md" p="lg">
        {analysisLogIsError ? (
          <UnableToLoadAlert />
        ) : !analysisLog ? (
          <Loader />
        ) : (
          <ScrollArea type="auto" pb="md">
            <AnalysisLogTable analysisLog={analysisLog} />
          </ScrollArea>
        )}
      </Paper>
    </>
  );
}

function generateReportFileName(clientName: string, reportingPeriodStart: string, reportingPeriodEnd: string) {
  const fileNameClientName = clientName.replace(' ', '_').replace(/[^a-zA-Z0-9_]/g, "").split('_').slice(0, 2).join('_');
  return `AnalysisSummary-${fileNameClientName}-${formatDateForFileName(reportingPeriodStart)}-${formatDateForFileName(reportingPeriodEnd)}.pdf`;
}

export function AnalysisSummaryReportView() {

  const isMobile = useIsMobile();
  // const isPrint = useIsPrint();

  const platformDefaultCtrlSize = usePlatformDefaultCtrlSize();

  const [searchParams, setSearchParams] = useSearchParams();

  const [generateClicked, setGenerateClicked] = useState(false);

  const { activeClient } = useContext(UserContext);

  const { data: clientTenant } = useQuery({
    queryKey: [QueryKey.GetClientTenant, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.xero.getClientTenant();
      return res.data;
    },
    enabled: !!activeClient
  });

  const [reportingPeriodOption, setReportingPeriodOption] = useState<ReportingPeriodOption | null>(null);

  const [reportingPeriodStartDatePickerValue, setReportingPeriodStartDatePickerValue] = useState<DateValue | null>(null);
  const [reportingPeriodEndDatePickerValue, setReportingPeriodEndDatePickerValue] = useState<DateValue | null>(null);

  useEffect(() => {
    setGenerateClicked(false);
  }, [reportingPeriodOption])

  let reportingPeriod: ReportingPeriod | null = null;

  if (reportingPeriodOption === ReportingPeriodOption.Manual && reportingPeriodStartDatePickerValue && reportingPeriodEndDatePickerValue) {
    reportingPeriod = {
      startDateTime: reportingPeriodStartDatePickerValue.toString(),
      endDateTime: reportingPeriodEndDatePickerValue.toString()
    };
  }

  if (reportingPeriodOption && reportingPeriodOption !== ReportingPeriodOption.Manual) {
    reportingPeriod = calculateReportingPeriodFromOption(reportingPeriodOption, clientTenant?.orgData?.financialYearEndDay, clientTenant?.orgData?.financialYearEndMonth);
  }

  let reportingPeriodSetBySearchParams = false;
  if (searchParams.get('reportingPeriodStart') && searchParams.get('reportingPeriodEnd') && !reportingPeriodOption && !reportingPeriodStartDatePickerValue && !reportingPeriodEndDatePickerValue) {
    reportingPeriod = {
      startDateTime: searchParams.get('reportingPeriodStart')!,
      endDateTime: searchParams.get('reportingPeriodEnd')!
    };
    reportingPeriodSetBySearchParams = true;
  }



  const { data: analysisActivity, refetch: refetchAnalysisActivity, isError: analysisActivityIsError, isLoading: analysisActivityIsLoading } = useQuery({
    queryKey: [QueryKey.GetAnalysisSummaryReportAnalysisActivity, activeClient?.id, reportingPeriod?.startDateTime, reportingPeriod?.endDateTime],
    queryFn: async () => {
      const res = await apiClient.clientReporting.getAnalysisActivity(activeClient!.id, reportingPeriod!.startDateTime, reportingPeriod!.endDateTime);
      return res.data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: reportingPeriodSetBySearchParams
  });

  const { data: noteworthyTradingPartners, refetch: refetchNoteworthyTradingPartners, isError: noteworthyTradingPartnersIsError, isLoading: noteworthyTradingPartnersIsLoading } = useQuery({
    queryKey: [QueryKey.GetAnalysisSummaryReportNoteworthyTradingPartners, activeClient?.id, reportingPeriod?.startDateTime, reportingPeriod?.endDateTime],
    queryFn: async () => {
      const res = await apiClient.clientReporting.getNoteworthyTradingPartners(activeClient!.id, reportingPeriod!.startDateTime, reportingPeriod!.endDateTime);
      return res.data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: reportingPeriodSetBySearchParams
  });

  const { data: userActivity, refetch: refetchUserActivity, isError: userActivityIsError, isLoading: userActivityIsLoading } = useQuery({
    queryKey: [QueryKey.GetAnalysisSummaryReportUserActivity, activeClient?.id, reportingPeriod?.startDateTime, reportingPeriod?.endDateTime],
    queryFn: async () => {
      const res = await apiClient.clientReporting.getUserActivity(activeClient!.id, reportingPeriod!.startDateTime, reportingPeriod!.endDateTime);
      return res.data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: reportingPeriodSetBySearchParams
  });


  const { data: analysisLog, refetch: refetchAnalysisLog, isError: analysisLogIsError, isLoading: analysisLogIsLoading } = useQuery({
    queryKey: [QueryKey.GetAnalysisSummaryReportAnalysisLog, activeClient?.id, reportingPeriod?.startDateTime, reportingPeriod?.endDateTime],
    queryFn: async () => {
      const res = await apiClient.clientReporting.getAnalysisLog(activeClient!.id, reportingPeriod!.startDateTime, reportingPeriod!.endDateTime);
      return res.data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: reportingPeriodSetBySearchParams
  });

  // const { data: alertsAndAdvisories, refetch: refetchAlertsAndAdvisories } = useQuery({
  //   queryKey: [QueryKey.GetAnalysisSummaryReportAlertsAndAdvisories, activeClient?.id, reportingPeriod?.startDateTime, reportingPeriod?.endDateTime],
  //   queryFn: async () => {
  //     const res = await apiClient.clientReporting.getAlertsAndAdvisories(activeClient!.id, reportingPeriod!.startDateTime, reportingPeriod!.endDateTime);
  //     return res.data;
  //   },
  //   refetchOnWindowFocus: false,
  //   refetchOnReconnect: false,
  //   enabled: reportingPeriodSetBySearchParams
  // });


  const { data: alertMessages, refetch: refetchAlertMessages, isError: alertMessagesIsError, isLoading: alertMessagesIsLoading } = useQuery({
    queryKey: [QueryKey.GetAnalysisSummaryReportAlertMessages, activeClient?.id, reportingPeriod?.startDateTime, reportingPeriod?.endDateTime],
    queryFn: async () => {
      const res = await apiClient.clientReporting.getAlertMessages(activeClient!.id, reportingPeriod!.startDateTime, reportingPeriod!.endDateTime);
      return res.data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: reportingPeriodSetBySearchParams
  });


  const handleGenerateClick = () => {
    if (!reportingPeriod) return;
    setSearchParams({
      reportingPeriodStart: reportingPeriod.startDateTime,
      reportingPeriodEnd: reportingPeriod.endDateTime
    }, { replace: true });
    refetchAnalysisActivity();
    refetchNoteworthyTradingPartners();
    refetchUserActivity();
    refetchAnalysisLog();
    refetchAlertMessages();
    setGenerateClicked(true);
  }


  return (
    <Container size="xl" py="xl" className={classes.container}>
      <Title order={2}>Analysis Summary Report</Title>
      <Text mt="md">
        An overview of analyses conducted by Meysey on your organization's data, highlighting significant alerts identified during the review period.
      </Text>

      <Space h="xl" />

      <Box className={classes.criteriaControls}>
        <Group align="flex-end">

          <Select
            size={platformDefaultCtrlSize}
            label="Reporting Period"
            w={{ base: "100%", md: "250px" }}
            data={
              Object.values(ReportingPeriodOption).map(k => ({
                value: k, label: reportingPeriodOptionLabels[k]
              }))
            }
            placeholder='Select a period'
            value={reportingPeriodOption}
            onChange={(val) => setReportingPeriodOption(val as ReportingPeriodOption)}
          />

          {reportingPeriod && (
            <>
              <DatePickerInput
                classNames={{
                  input: classes.datePickerInput
                }}
                w={{ base: "100%", md: "200px" }}
                disabled={reportingPeriodOption !== ReportingPeriodOption.Manual}
                label="Start Date"
                size={platformDefaultCtrlSize}
                placeholder='Select a date'
                value={reportingPeriod?.startDateTime ? dayjs(reportingPeriod.startDateTime).toDate() : reportingPeriodStartDatePickerValue}
                onChange={setReportingPeriodStartDatePickerValue}
              />
              <DatePickerInput
                classNames={{
                  input: classes.datePickerInput
                }}
                label="End Date"
                disabled={reportingPeriodOption !== ReportingPeriodOption.Manual}
                w={{ base: "100%", md: "200px" }}
                size={platformDefaultCtrlSize}
                placeholder='Select a date'
                value={reportingPeriod?.endDateTime ? dayjs(reportingPeriod.endDateTime).toDate() : reportingPeriodEndDatePickerValue}
                onChange={setReportingPeriodEndDatePickerValue}
              />
            </>
          )}

          <Group>
            <Button loading={generateClicked && (analysisActivityIsLoading || noteworthyTradingPartnersIsLoading || userActivityIsLoading || analysisLogIsLoading || alertMessagesIsLoading)} disabled={!reportingPeriod} onClick={() => handleGenerateClick()}>Generate</Button>
            <Button disabled={!reportingPeriod || !(searchParams.get('reportingPeriodStart') && searchParams.get('reportingPeriodEnd'))} component={'a'} target="_blank" href={!reportingPeriod ? '#' : apiClient.pdfMirrorUrl(window.location.href, generateReportFileName(clientTenant?.tenantName || '', reportingPeriod!.startDateTime, reportingPeriod!.endDateTime))} variant="outline" leftSection={<IconDownload size="1rem" />}>Download</Button>
          </Group>
        </Group>

        <Space h="xl" />
        <Space h="xl" />
      </Box>

      {
        reportingPeriod && (generateClicked || reportingPeriodSetBySearchParams) && (
          <ReportBody
            reportingPeriod={reportingPeriod}
            isMobile={!!isMobile}
            // isPrint={!!isPrint}
            analysisActivity={analysisActivity}
            analysisActivityIsError={analysisActivityIsError}
            noteworthyTradingPartners={noteworthyTradingPartners}
            noteworthyTradingPartnersIsError={noteworthyTradingPartnersIsError}
            userActivity={userActivity}
            userActivityIsError={userActivityIsError}
            analysisLog={analysisLog}
            analysisLogIsError={analysisLogIsError}
            alertMessages={alertMessages}
            alertMessagesIsError={alertMessagesIsError}
          />
        )
      }

    </Container>
  );
}
