import { Container, Title, Text, Center, Paper } from '@mantine/core';

import classes from './PracticeSignUpView.module.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { EmailSignUpForm } from './EmailSignUpForm';
import { Notifications } from '@mantine/notifications';
import { EmailVerificationCheckpoint } from './EmailVerificationCheckpoint';
import { SelectMethod } from './SelectMethod';
import { ProfileForm } from './ProfileForm';
import { SignOutButton } from './SignOutButton';



export function PracticeSignUpView() {


  const location = useLocation();  
  
  return (
    <>
      <Notifications position="top-right" />
      <SignOutButton next='../' />
      <Container mb="lg" mt="100px">
        <Title ta="center" className={classes.title}>Welcome to Meysey</Title>
        <Text ta="center" fz="lg" mt="sm">Create your practice account to get started</Text>
        <Routes>
          <Route path="*" element={
            <Center>
              <SelectMethod next="verify-email" />
            </Center>
          } />

          <Route path="email" element={
            <Center>
              <Paper withBorder shadow="md" radius="md" p="xl" mt="xl" w={{ base: '100%', md: '40%' }}>

                <EmailSignUpForm next="../verify-email" />

              </Paper>
            </Center>
          } />

          <Route path="verify-email" element={
            <Center>
              <Paper withBorder shadow="md" radius="md" p="xl" mt="xl" w={{ base: '100%', md: '60%' }}>

                <EmailVerificationCheckpoint next="../profile" restart="../" />

              </Paper>
            </Center>
          } />


          <Route path="profile" element={
            <Center>
              <Paper withBorder shadow="md" radius="md" p="xl" mt="xl" w={{ base: '100%', md: '60%' }}>
                <ProfileForm restart="../" next={location.state?.destination || "../.."} />
              </Paper>
            </Center>
          } />

        </Routes>

      </Container>
    </>
  );
}

