import { Container, Title, Text, Space, Modal, Paper, Table, Center, Loader, Group, Button, ActionIcon, rem, UnstyledButton, TextInput, TableScrollContainer, ScrollArea } from '@mantine/core';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../../userContext';
import { NoClientsHeroUnit } from './NoClientsHeroUnit';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { CreatePracticeClientSteps } from './CreatePracticeClientSteps';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { apiClient } from '../../../api/apiClient';
import { QueryKey } from '../../../queryKeys';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { Practice, Client, ClientSortKey, SortDirection } from '../../../api/generated';
import { IconChevronDown, IconChevronLeft, IconChevronRight, IconChevronUp, IconSearch, IconSelector, IconUserPlus } from '@tabler/icons-react';
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { CompleteXeroIntegrationSteps } from './CompleteXeroIntegrationSteps';
import { ClientRow } from './ClientRow';

import classes from './ClientsView.module.css';

function CompleteXeroIntegrationModal({ activePractice }: { activePractice: Practice }) {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const clientId = searchParams.get('clientId');
  const practiceId = searchParams.get('practiceId');
  const reconnectXeroTenantId = searchParams.get('reconnectXeroTenantId');

  if (
    !practiceId || !clientId || practiceId !== activePractice.id || reconnectXeroTenantId
  ) {
    return <Navigate to=".." />;
  }

  // todo - render a reconnect success/fail screen
  return (
    <Modal
      opened={true}
      onClose={() => navigate('..')}
      closeOnClickOutside={false}
      title={<Text fw={500}>Finalise Xero Integration</Text>}
      size="xl"
      centered
    >
      <CompleteXeroIntegrationSteps onClose={() => navigate('..')} practiceId={practiceId} clientId={clientId} />
    </Modal>
  );

}

function SortableTh({ children, onSort, sorted, sortDirection }: { children: React.ReactNode, onSort(): void, sorted: boolean, sortDirection: 'asc' | 'desc' }) {
  const Icon = sorted ? (sortDirection === 'asc' ? IconChevronDown : IconChevronUp) : IconSelector;
  return (
    <Table.Th className={classes.th}>
      <UnstyledButton className={classes.sortControl} onClick={onSort}>
        {/* width: 100%;
                  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md); */}
        <Group justify="space-between" wrap="nowrap">
          <Text fw={700} fz="sm">
            {children}
          </Text>
          <Center className={classes.sortIcon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

export function ClientsView() {


  const { activePractice } = useContext(UserContext);

  const [addClientOpened, { open: openAddClient, close: closeAddClient }] = useDisclosure();

  const [desiredOffset, setDesiredOffset] = useState(0);
  const desiredLimit = 15;

  const [sortBy, setSortBy] = useState<ClientSortKey>(ClientSortKey.Name);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Asc);

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebouncedValue(searchValue, 300);


  const { data: clients, isPending: clientsIsPending, isError: clientsIsError, isPlaceholderData } = useQuery({
    queryKey: [QueryKey.GetClients, activePractice?.id, desiredLimit, desiredOffset, sortBy, sortDirection, debouncedSearchValue],
    queryFn: async () => {
      const res = await apiClient.practice.getPracticeClients(activePractice?.id!, desiredLimit, desiredOffset, sortBy, sortDirection, debouncedSearchValue || undefined);
      return res.data;
    },
    enabled: !!activePractice,
    placeholderData: keepPreviousData
  });




  const getRows = (clients: Client[]) => {
    return clients.map((client) => (
      <ClientRow client={client} key={client.id} />
    ));
  }

  const getSortProps = (key: ClientSortKey) => {
    return {
      onSort: () => {
        setSortDirection(sortBy === key && sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc);
        setSortBy(key);
      },
      sorted: sortBy === key,
      sortDirection
    }
  };

  let content: React.ReactNode;

  if (clientsIsError) {
    content = (
      <Center mt={250}>
        <UnableToLoadAlert />
      </Center>
    );
  } else if (clientsIsPending || !clients) {
    content = (
      <Center mt={250}><Loader /></Center>
    );
  } else if (clients.data.length === 0 && !debouncedSearchValue) {
    content = (
      <NoClientsHeroUnit onAddClick={openAddClient} />
    );
  } else {

    const fromIdx = clients.offset || 1;
    const toIdx = ((clients.offset || 0) + (clients.limit || 0)) ? Math.min(((clients.offset || 0) + (clients.limit || 0)), clients.total) : clients.total;
    const maxIdx = clients.total;
    const prevEnabled = !isPlaceholderData && fromIdx > 1;
    const nextEnabled = !isPlaceholderData && clients.total > toIdx;

    const pageControl = (
      <Group px="md" justify="flex-end" gap="xs">
        {/* <Group justify="flex-end" gap="xs"> */}
        <Text c="dimmed">{fromIdx}-{toIdx} of {maxIdx}</Text>
        <ActionIcon
          variant="subtle"
          size="sm"
          disabled={!prevEnabled} onClick={() => setDesiredOffset(desiredOffset - desiredLimit)}
        >
          <IconChevronLeft />
        </ActionIcon>
        <ActionIcon
          variant="subtle"
          size="sm"
          disabled={!nextEnabled}
          onClick={() => setDesiredOffset(desiredOffset + desiredLimit)}
          loading={isPlaceholderData}
        >
          <IconChevronRight />
        </ActionIcon>
        {/* </Group> */}
      </Group>
    );

    content = (
      <>
        <Group justify="space-between" py="lg">
          <TextInput
            placeholder="Search clients"
            value={searchValue}
            w={rem(300)}
            onChange={event => setSearchValue(event.currentTarget.value)}
            rightSection={<IconSearch style={{ width: rem(16), height: rem(16) }} />}
          />
          <Button leftSection={<IconUserPlus size="22px" />} onClick={openAddClient}>Add Client</Button>
        </Group>
        <Paper shadow="xs" radius="md" my="xs">
          <ScrollArea type="auto">
            <Table verticalSpacing="md">
              <Table.Thead>
                <Table.Tr>
                  <SortableTh
                    {...getSortProps(ClientSortKey.Name)}
                  >
                    Client Name
                  </SortableTh>
                  <SortableTh
                    {...getSortProps(ClientSortKey.PracticeReference)}
                  >
                    Practice Reference
                  </SortableTh>
                  <Table.Th>Status</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{getRows(clients.data)}</Table.Tbody>
            </Table>
          </ScrollArea>
        </Paper>
        {pageControl}
      </>
    );
  }


  return (
    <>
      <Modal
        opened={addClientOpened}
        onClose={closeAddClient}
        closeOnClickOutside={false}
        title={<Text fw={500}>Add Client</Text>}
        centered
        size="xl"
      >
        <CreatePracticeClientSteps practice={activePractice!} onClose={closeAddClient} />
      </Modal>


      {activePractice && (
        <Routes>
          <Route path="complete-xero-integration" element={<CompleteXeroIntegrationModal activePractice={activePractice} />} />
        </Routes>
      )}

      <Container size="xl" py="xl">
        <Title order={2}>Clients</Title>
        <Text mt="md" fz="lg" fw={700} c="dimmed">{activePractice?.name}</Text>

        <Space h="xl" />

        {content}


        <Space h="lg" />

      </Container>
    </>
  );
}