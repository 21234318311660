import { Paper, ScrollArea } from '@mantine/core';

import classes from './MessageList.module.css';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import { Message } from '../../api/generated';
import { MessageListItem } from './MessageListItem';

export function MessageList({ messages }: { messages: Message[] }) {

  const items = sortBy(messages, m => -dayjs(m.created).unix()).map((message, idx) => <MessageListItem message={message} key={idx} />);

  return (
    <>
      <Paper mt="xs" shadow="xs" radius="md">
        <ScrollArea type="auto">
          <div className={classes.root}>
            {items}
          </div>
        </ScrollArea>
      </Paper>
      {/* <Center mt={75}><Text c="dimmed">End of messages</Text></Center> */}
    </>
  );

}