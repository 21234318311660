import { AppShell, Badge, NavLink } from '@mantine/core';
import { IconActivityHeartbeat, IconAddressBook, IconChartHistogram, IconFileInvoice, IconHome, IconMail, IconMessageCircleQuestion, IconUserDollar, IconUsers, IconUsersGroup } from '@tabler/icons-react';
import { User } from './User';
import { Link, useMatches } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../userContext';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../queryKeys';
import { apiClient } from '../api/apiClient';

interface NavbarProps {
  opened: boolean;
}

export function Navbar({ opened }: NavbarProps) {

  const { user, activeClient } = useContext(UserContext);

  const practiceNav = user?.practiceId && !activeClient;

  const { data: unreadMessagesCount } = useQuery({
    queryKey: [QueryKey.GetMessages, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.message.getMessages(0, 0);
      return res.data;
    },
    enabled: !!activeClient
  });

  const routeMatches = useMatches();
  return (
    <AppShell.Navbar p="md" hidden={!opened} >
      <AppShell.Section grow mt="xs">
        {!practiceNav && (
          <>
            <NavLink
              label="Home"
              leftSection={<IconHome size="1rem" />}
              component={Link}
              to="/"
              active={routeMatches.some(rm => rm.id === 'dashboard') || routeMatches.some(rm => rm.id === 'connect')}
            />
            <NavLink
              label="Message Centre"
              description={false && "Items for your attention"}
              disabled={!user}
              leftSection={
                unreadMessagesCount?.unread ? (
                  <Badge size="xs" variant="filled" color="red" w={16} h={16} p={0}>
                    {unreadMessagesCount.unread}
                  </Badge>
                ) : (
                  <IconMail size="1rem" />
                )
              }
              component={Link}
              to="/message-centre"
              active={routeMatches.some(rm => rm.pathname === '/message-centre')}
            />
            <NavLink
              label="Scanning Activity"
              leftSection={<IconActivityHeartbeat size="1rem" />}
              disabled={!user}
              component={Link}
              to="/scanning-activity"
              active={routeMatches.some(rm => rm.id === 'scanning-activity')}
            // rightSection={<ChevronRight size="0.8rem" />}
            />
            <NavLink
              label="Suppliers & Customers"
              leftSection={<IconAddressBook size="1rem" />}
              disabled={!user}
              component={Link}
              to="/suppliers-and-customers"
              active={routeMatches.some(rm => rm.pathname === '/suppliers-and-customers')}
            />
            <NavLink
              label="Bills & Invoices"
              leftSection={<IconFileInvoice size="1rem" />}
              disabled={!user}
              component={Link}
              to="/bills-and-invoices"
              active={routeMatches.some(rm => rm.pathname === '/bills-and-invoices')}
            />
           <NavLink
              label="Reports"
              leftSection={<IconChartHistogram size="1rem" />}
              disabled={!user}
              component={Link}
              to="/reports"
              active={routeMatches.some(rm => rm.pathname === '/reports')}
            />
            {/* <NavLink
          label="Bank Transactions"
          leftSection={<IconBuildingBank size="1rem" />}
          component={Link}
          to="/bank-transactions"
          active={!!~routeMatches.indexOf('/bank-transactions')}
        /> */}
            <NavLink
              disabled
              label="Payroll & Expenses"
              description="Coming soon"
              leftSection={<IconUserDollar size="1rem" />}
            // rightSection={<ChevronRight size="0.8rem" />}
            />

          </>
        )}
        {practiceNav && (
          <>
            <NavLink
              label="Clients"
              leftSection={<IconUsersGroup size="1rem" />}
              component={Link}
              to="/practice"
              active={routeMatches.some(rm => rm.id === 'clients')}
            />
            <NavLink
              label="Colleagues"
              leftSection={<IconUsers size="1rem" />}
              component={Link}
              to="/practice/colleagues"
              active={routeMatches.some(rm => rm.id === 'colleagues')}
            />
            {/* <NavLink
              label="Message Centre"
              description={false && "Items for your attention"}
              disabled={!user}
              leftSection={
                <IconMail size="1rem" />
              }
              component={Link}
              to="/message-centre"
              active={routeMatches.some(rm => rm.pathname === '/message-centre')}
            /> */}
          </>
        )}
        <NavLink
          label="Contact Us"
          leftSection={<IconMessageCircleQuestion size="1rem" />}
          onClick={() => (window as any).Beacon('open')}
        />




      </AppShell.Section>
      <AppShell.Section>
        {/* <div className={classes.footer}> */}
        <User />
        {/* </div> */}

      </AppShell.Section>
    </AppShell.Navbar>
  );
}