import dayjs from 'dayjs';
import { ReportingPeriodCalculator } from '../../../lib/reporting/reportingPeriodCalculator'
import { isNullOrUndefined } from '../../../lib/helpers';

export enum ReportingPeriodOption {
  LastCompleteMonth = 'LastCompleteMonth',
  Last3CompleteMonths = 'Last3CompleteMonths',
  Last12CompleteMonths = 'Last12CompleteMonths',
  LastFinancialYear = 'LastFinancialYear',
  CurrentMonthToDate = 'CurrentMonthToDate',
  FinancialYearToDate = 'FinancialYearToDate',
  Manual = 'Manual'
}

export const reportingPeriodOptionLabels: Record<ReportingPeriodOption, string> = {
  LastCompleteMonth: 'Last Complete Month',
  Last3CompleteMonths: 'Last 3 Complete Months',
  Last12CompleteMonths: 'Last 12 Complete Months',
  LastFinancialYear: 'Last Financial Year',
  CurrentMonthToDate: 'Current Month To Date',
  FinancialYearToDate: 'Financial Year To Date',
  Manual: 'Manual'
}

export function calculateReportingPeriodFromOption(reportingPeriodOption: Exclude<ReportingPeriodOption, ReportingPeriodOption.Manual>, finanicalYearEndDay?: number, financialYearEndMonth?: number) {

  switch (reportingPeriodOption) {
    case ReportingPeriodOption.LastCompleteMonth:
      return ReportingPeriodCalculator.getPriorMonths(dayjs().format(), 1);
    case ReportingPeriodOption.Last3CompleteMonths:
      return ReportingPeriodCalculator.getPriorMonths(dayjs().format(), 3);
    case ReportingPeriodOption.Last12CompleteMonths:
      return ReportingPeriodCalculator.getPriorMonths(dayjs().format(), 12);
    case ReportingPeriodOption.LastFinancialYear:
      if (isNullOrUndefined(finanicalYearEndDay) || isNullOrUndefined(financialYearEndMonth)) {
        throw new Error('financialYearEndDay and financialYearEndMonth must be supplied');
      }
      return ReportingPeriodCalculator.getPriorFinancialYear(dayjs().format(), finanicalYearEndDay, financialYearEndMonth);
    case ReportingPeriodOption.CurrentMonthToDate:
      return ReportingPeriodCalculator.getCurrentMonthToDate();
    case ReportingPeriodOption.FinancialYearToDate:
      if (isNullOrUndefined(finanicalYearEndDay) || isNullOrUndefined(financialYearEndMonth)) {
        throw new Error('financialYearEndDay and financialYearEndMonth must be supplied');
      }
      return ReportingPeriodCalculator.getCurrentFinancialYearToDate(finanicalYearEndDay, financialYearEndMonth);
  }

}
