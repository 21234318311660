import { Paper, ScrollArea, Table } from '@mantine/core';
import { InvoicesHeaderRow, InvoicesRow } from './InvoicesRow';
import { Invoice } from '../../api/generated';
import { useNavigate } from 'react-router-dom';

export function InvoicesTable({ invoices }: { invoices: Invoice[] }) {


  const navigate = useNavigate();

  return (
    <>
      <Paper shadow="xs" radius="md">
        <ScrollArea type="auto">
          <Table verticalSpacing="md" highlightOnHover>
            <Table.Thead>
              <InvoicesHeaderRow />
            </Table.Thead>
            <Table.Tbody>
              {invoices.map((invoice, idx) => <InvoicesRow key={idx} invoice={invoice} onClick={() => navigate(`invoices/${invoice.invoiceID}`)} />)}
            </Table.Tbody>
          </Table>
        </ScrollArea>
      </Paper>
    </>
  )

}