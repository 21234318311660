import { Text, Modal, Tabs, Stack, Button, Group, TextInput, Space } from '@mantine/core';
import { Client, PatchClientDto } from '../../../api/generated';
import { isNotEmpty, useForm } from '@mantine/form';
import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKey, QueryKey } from '../../../queryKeys';
import { apiClient } from '../../../api/apiClient';
import { showGeneralChangesSavedSuccessfullyNotification, showGeneralErrorNotification } from '../../../lib/helpers';
import { ColleagueAccessTable } from './ColleagueAccessTable';
import { UserContext } from '../../../userContext';
import { usePlatformDefaultCtrlSize } from '../../../hooks/usePlatformDefaultCtrlSize';

export function EditClientModal({ opened, onClose, client }: { opened: boolean, onClose: () => void, client: Client }) {

  const platformDefaultCtrlSize = usePlatformDefaultCtrlSize();

  const { activePractice } = useContext(UserContext); 

  const [saveEnabled, setSaveEnabled] = useState(false);

  const editClientForm = useForm({
    name: `editClient-${client.id}`,
    mode: 'uncontrolled',    
    initialValues: {
      name: client.name,
      practiceReference: client.practiceReference
    },
    validate: {
      name: isNotEmpty('Name is required')     
    },
    onValuesChange: () => {
      setSaveEnabled(editClientForm.isDirty());
    }
  });

  const queryClient = useQueryClient();
  const { mutate: patchClient, isPending: patchClientIsPending } = useMutation({
    mutationKey: [MutationKey.PatchClient, client.id],
    mutationFn: async (values: PatchClientDto) => {
      const res = await apiClient.client.patchClient(client.id, values);
      return res.data;
    },
    onError: () => {
      showGeneralErrorNotification();
    },
    onSuccess: (updatedClient: Client) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.GetClient, client.id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.GetClients] });
      showGeneralChangesSavedSuccessfullyNotification();
      // onClose();
      editClientForm.setInitialValues({
        name: updatedClient.name,
        practiceReference: updatedClient.practiceReference
      });
      setSaveEnabled(false);
    }
  });

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
        editClientForm.reset();        
      }}
      closeOnClickOutside={false}
      title={<Text fw={500}>Editing {client.name}</Text>}
      centered
      size="lg"
    >
      <Tabs defaultValue="details">
        <Tabs.List>
          <Tabs.Tab value="details">
            Basic Details
          </Tabs.Tab>
          <Tabs.Tab value="access">
            Colleague Access
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="details">
          <form onSubmit={editClientForm.onSubmit((values) => patchClient(values))}>
            <Stack p="lg">
              <TextInput
                label="Name"
                size={platformDefaultCtrlSize}
                placeholder="Client name"
                description="Company or sole trader name"
                key={editClientForm.key('name')}
                {...editClientForm.getInputProps('name')}
              />
              <TextInput
                size={platformDefaultCtrlSize}
                label="Practice reference"
                placeholder="Practice reference"
                description="Optional for internal use"
                key={editClientForm.key('practiceReference')}
                {...editClientForm.getInputProps('practiceReference')}
              />
              <Group justify="flex-end" mt="lg">
                {saveEnabled && (<Button variant="default" onClick={() => editClientForm.reset()}>Discard</Button>)}
                <Button w={{ base: '100%', sm: 'auto' }} type="submit" disabled={!saveEnabled} loading={patchClientIsPending}>Save</Button>
              </Group>
            </Stack>
          </form>
        </Tabs.Panel>

        <Tabs.Panel value="access">
          <Space h="lg"/>
          <ColleagueAccessTable clientId={client.id} practice={activePractice!} />
        </Tabs.Panel>

      </Tabs>
    </Modal>
  );


}