
export enum QueryKey {
  GetCurrentUser = "GetCurrentUser",
  GetActiveTenant = "GetActiveTenant",
  GetTenants = "GetTenants",
  GetAnalysisByContactId = "GetAnalysisByContactId",
  GetBankTransactions = "GetBankTransactions",
  GetContacts = "GetContacts",
  GetContactAnalysisById = "GetContactAnalysisById",
  GetBankTransactionDetailById = "GetBankTransactionDetailById",
  GetTenantAnalysisState = "GetTenantAnalysisState",
  GetTenantAnalysisStats = "GetTenantContactAnalysisStats",
  GetContact = "GetContact",
  GetMessages = 'GetMessages',
  GetXeroUsers = "GetXeroUsers",
  GetXeroTenantConnectionMeyseyUsers = "GetXeroTenantConnectionMeyseyUsers",
  GetClientUserInvites = "GetXeroUserInvites",
  GetAdditionalAlertingContacts = "GetAdditionalAlertingContacts",
  GetUserAlertingPreferences = "GetUserAlertingPreferences",
  SetUserAlertingPreferences = "SetUserAlertingPreferences",
  GetPresentSubscription = "GetSubscription",
  GetPendingSubscription = "GetPendingSubscription",
  GetInvoices = "GetInvoices",
  GetPayments = "GetPayments",
  GetInvoice = "GetInvoice",
  GetTaxTypes = "GetTaxTypes",
  GetBills = "GetBills",
  GetPaymentHistory = "GetPaymentHistory",
  GetPayment = "GetPayment",
  GetBankTransaction = "GetBankTransaction",
  GetAccounts = "GetAccounts",
  GetBankTransactionHistory = "GetBankTransactionHistory",
  GetInvoiceAnalysisState = "GetInvoiceAnalysisState",
  GetBankTransactionAnalysisState = "GetBankTransactionAnalysisState",
  GetPractices = "GetPractices",
  GetClients = "GetClients",
  GetPracticeUsers = "GetPracticeUsers",
  GetClient = "GetClient",
  GetClientXeroUsers = "GetClientXeroUsers",
  GetActivePractice = "GetActivePractice",
  GetActiveClient = "GetActiveClient",
  GetClientTenant = "GetClientTenant",
  GetClientXeroTenantConnection = "GetClientXeroTenantConnection",
  GetPracticeUserInvites = "GetPracticeUserInvites",
  GetPendingPracticeUserInvite = "GetPendingPracticeUserInvite",
  GetMessagesByClient = "GetMessagesByClient",
  GetRecentScanningActivity = "GetRecentScanningActivity",
  GetClientFraudOutreachSetting = "GetClientFraudOutreachSetting",
  GetAnalysisSummaryReportAnalysisActivity = "GetAnalysisSummaryReportAnalysisActivity",
  GetAnalysisSummaryReportNoteworthyTradingPartners = "GetAnalysisSummaryNoteworthyTradingPartners",
  GetAnalysisSummaryReportUserActivity = "GetAnalysisSummaryReportUserActivity",
  GetAnalysisSummaryReportAnalysisLog = "GetAnalysisSummaryReportAnalysisLog",
  GetAnalysisSummaryReportAlertsAndAdvisories = "GetAnalysisSummaryReportAlertsAndAdvisories",
  GetAnalysisSummaryReportAlertMessages = "GetAnalysisSummaryReportAlertMessages"
}


export enum MutationKey {
  ChangeTenant = "ChangeTenant",
  SetMessageRead = "SetMessageRead",
  Logout = "Logout",
  CreateClientUserInvite = "CreateXeroUserInvite",
  SetAdditionalAlertingContacts = "SetAdditionalAlertingContacts",
  SetUserAlertingPreferences = "SetUserAlertingPreferences",
  DisconnectXero = "DisconnectXero",
  CreatePractice = "CreatePractice",
  CreatePracticeClient = "CreatePracticeClient",
  SetPracticeClientUserAccess = "SetPracticeClientUserAccess",
  SetClientXeroTenant = "SetClientXeroTenant",
  SetActivePractice = "SetActivePractice",
  SetActiveClient = "SetActiveClient",
  ClearActiveClient = "ClearActiveClient",
  PatchClient = "PatchClient",
  CreatePracticeUserInvite = "CreatePracticeUserInvite",
  AcceptPracticeUserInvite = "AcceptPracticeUserInvite",
  UpsertClientFraudOutreachSetting = "UpsertClientFraudOutreachSetting",
  ResendPracticeUserInvite = "ResendPracticeUserInvite"
}

// ! this is parked for now... likely solved by wrapping mutations in functions
// rather than defining them inline each time
export const queryMeta: Partial<Record<QueryKey | MutationKey | string, {
  invalidatedBy?: (QueryKey | MutationKey)[],
  removedBy?: (QueryKey | MutationKey)[],
}>> = {
  [QueryKey.GetCurrentUser]: {
  },
  [QueryKey.GetActiveTenant]: {
  },
  [QueryKey.GetTenants]: {
  },
  [QueryKey.GetAnalysisByContactId]: {
  },
  [QueryKey.GetBankTransactions]: {
  },
  [QueryKey.GetContacts]: {
  },
  [QueryKey.GetContactAnalysisById]: {
  },
  [QueryKey.GetBankTransactionDetailById]: {
  },
  [QueryKey.GetTenantAnalysisState]: {
  },
  [QueryKey.GetTenantAnalysisStats]: {
  },
  // Add any missing keys here with their respective invalidatedBy array
};

// export function isKeyInvalidatedBy(possiblyInvalidatedKey: string, otherKey: QueryKey | MutationKey) {
//   return !!queryMeta[possiblyInvalidatedKey]?.invalidatedBy?.includes(otherKey);
// }

// export function getKeysInvalidatedBy(key: QueryKey | MutationKey) {  
//   return Object.entries(queryMeta).filter(([_, v]) => !!~(v?.invalidatedBy || []).indexOf(key)).map(([k]) => k);
// }

// export function isKeyRemovedBy(possiblyInvalidatedKey: string, otherKey: QueryKey | MutationKey) {
//   return !!queryMeta[possiblyInvalidatedKey]?.removedBy?.includes(otherKey);
// }

// export function getKeysRemovedBy(key: QueryKey | MutationKey) {  
//   return Object.entries(queryMeta).filter(([_, v]) => !!~(v?.removedBy || []).indexOf(key)).map(([k]) => k);
// }