import { Button, Skeleton, Stack, TextInput } from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { PasswordStrength, getPasswordStrength } from './PasswordStrength';
import classes from './EmailSignUpForm.module.css';
import { auth } from '../../../firebase';
import { FirebaseError } from 'firebase/app';
import { notifications } from '@mantine/notifications';
import { useEffect, useRef } from 'react';
import { useAuthState, useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { showGeneralErrorNotification } from '../../../lib/helpers';
import { usePlatformDefaultCtrlSize } from '../../../hooks/usePlatformDefaultCtrlSize';

type FormValues = {
  email: string;
  password: string;
};

export function EmailSignUpForm({ next }: { next: string }) {
  const platformDefaultCtrlSize = usePlatformDefaultCtrlSize();

  const form = useForm<FormValues>({
    mode: 'uncontrolled',
    validate: {
      email: isEmail('Invalid email'),
      password: (value: string) => getPasswordStrength(value) === 100 ? null : 'Password is too weak'
    }
  });

  const [existingUser, existingUserLoading, existingUserError] = useAuthState(auth);

  const [
    createUserWithEmailAndPassword,
    createdUser,
    createUserLoading,
    createUserError,
  ] = useCreateUserWithEmailAndPassword(auth, {
    sendEmailVerification: true
  });

  const previousCreateUserErrorRef = useRef<unknown>(null);

  useEffect(() => {
    if (createUserError !== previousCreateUserErrorRef.current) {
      previousCreateUserErrorRef.current = createUserError;

      if (!createUserError) return;

      if ((createUserError as FirebaseError).code === 'auth/email-already-in-use') {
        form.setFieldError('email', 'Email is already in use');
        return;
      } else {
        showGeneralErrorNotification();
      }
    }

  }, [createUserError, form]);

  if (existingUser || createdUser) {
    return <Navigate to={next} />;
  }

  const handleSubmit = async (values: FormValues) => {
    createUserWithEmailAndPassword(values.email, values.password);
  }

  return (
    <div>
      {existingUserLoading ? (
        <>
          <Skeleton animate h={10} mt={6} />
          <Skeleton animate h={10} mt={6} />
          <Skeleton animate h={10} mt={6} />
          <Skeleton animate h={10} mt={6} />
        </>
      ) : existingUserError ? (
        <UnableToLoadAlert />
      ) : (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>

            <TextInput
              size={platformDefaultCtrlSize}
              disabled={createUserLoading}
              label="Email"
              placeholder="Email"
              key={form.key('email')}
              required
              {...form.getInputProps('email')}
            />

            <PasswordStrength
              size={platformDefaultCtrlSize}
              disabled={createUserLoading}
              label="Password"
              placeholder="Password"
              key={form.key('password')}
              classNames={{ error: classes.passwordError }}
              required
              {...form.getInputProps('password')}
            />


            <Button size={platformDefaultCtrlSize} type="submit" mt="lg" loading={createUserLoading}>Continue</Button>

          </Stack>
        </form>
      )}
    </div>
  );

}