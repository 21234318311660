import { Box, Center, Loader, ScrollArea, Switch, Table } from '@mantine/core';
import { Client, ClientPracticeUserAccess, Practice, PracticeUserInvite, User } from '../../../api/generated';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MutationKey, QueryKey } from '../../../queryKeys';
import { apiClient } from '../../../api/apiClient';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import classes from './ColleagueAccessTable.module.css';

export function ColleagueAccessTable({ practice, clientId, isDuringClientCreation }: { practice: Practice, clientId: string, isDuringClientCreation?: boolean }) {


  const queryClient = useQueryClient();

  const { data: client, isPending: clientPending, isError: clientError } = useQuery({
    queryKey: [QueryKey.GetClient, clientId],
    queryFn: async () => {
      const res = await apiClient.client.findClientById(clientId);
      return res.data;
    }
  });

  const { data: practiceUsers, isPending: practiceUsersPending, isError: practiceUsersError } = useQuery({
    queryKey: [QueryKey.GetPracticeUsers, practice.id],
    queryFn: async () => {
      const res = await apiClient.practice.getPracticeUsers(practice.id);
      return res.data;
    }
  });

  const { data: practiceUserInvites, isPending: practiceUserInvitesIsPending, isError: practiceUsersInvitesIsError } = useQuery({
    queryKey: [QueryKey.GetPracticeUserInvites, practice.id],
    queryFn: async () => {
      const res = await apiClient.practice.getPracticeUserInvites(practice.id, true);
      return res.data;
    }
  });


  const { mutate: setPracticeClientUserAccess, isPending: setPracticeClientUserAccessPending } = useMutation({
    mutationKey: [MutationKey.SetPracticeClientUserAccess, clientId],
    mutationFn: async (data: ClientPracticeUserAccess[],) => {
      const res = await apiClient.client.setClientPracticeUserAccess(client!.id, data);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.GetClient, clientId], data)
    },
  });

  const handleCanAccessChange = (checked: boolean, { userId, inviteId }: { userId: string | null, inviteId: string | null }) => {

    const practiceUserAccess = client!.practiceUserAccess;

    const existingUserEntry = practiceUserAccess.find(u => (userId && u.userId === userId) || (inviteId && u.inviteId === inviteId));

    const updatedPracticeClientUserAccess = [
      ...practiceUserAccess.filter(v => (userId && v.userId !== userId) || (inviteId && v.inviteId !== inviteId)),
      { userId, inviteId, isAccountManager: existingUserEntry?.isAccountManager || false, canAccess: checked }
    ];

    setPracticeClientUserAccess(updatedPracticeClientUserAccess);
  }

  const handleIsAccountManagerChange = (checked: boolean, { userId, inviteId }: { userId: string | null, inviteId: string | null }) => {

    const practiceUserAccess = client!.practiceUserAccess;

    const existingUserEntry = practiceUserAccess.find(u => (userId && u.userId === userId) || (inviteId && u.inviteId === inviteId));

    const updatedPracticeClientUserAccess = [
      ...practiceUserAccess.filter(v => (userId && v.userId !== userId) || (inviteId && v.inviteId !== inviteId)),
      { userId, inviteId, canAccess: !checked ? !!existingUserEntry?.canAccess : true, isAccountManager: checked }
    ];


    setPracticeClientUserAccess(updatedPracticeClientUserAccess);

  }

  const getRows = (practiceUsers: User[], practiceUserInvites: PracticeUserInvite[], client: Client) => {
    return [...practiceUsers.map((practiceUser) => {

      const clientUserAccess = client.practiceUserAccess.find(u => u.userId === practiceUser.id);

      const isCreator = isDuringClientCreation && practiceUser.id === client.createdBy;
      const canAccess = !!(clientUserAccess && clientUserAccess.canAccess);
      const isAccountManager = !!(clientUserAccess && clientUserAccess.isAccountManager);

      return {
        userId: practiceUser.id,
        inviteId: null,
        firstName: practiceUser.firstName,
        lastName: practiceUser.lastName,
        email: practiceUser.email,
        isCreator,
        canAccess,
        isAccountManager
      };
    }), ...practiceUserInvites.map((practiceUserInvite) => {

      const clientUserAccess = client.practiceUserAccess.find(u => u.inviteId === practiceUserInvite.id);

      const canAccess = !!(clientUserAccess && clientUserAccess.canAccess);
      const isAccountManager = !!(clientUserAccess && clientUserAccess.isAccountManager);

      return {
        userId: null,
        inviteId: practiceUserInvite.id,
        firstName: practiceUserInvite.recipientFirstName,
        lastName: practiceUserInvite.recipientLastName,
        email: practiceUserInvite.recipientEmail,
        isCreator: false,
        canAccess,
        isAccountManager
      };


    })].map((entry, idx) => {
      return (
        <Table.Tr key={idx}>
          <Table.Td w="40%" className={classes.name}>{entry.firstName} {entry.lastName}</Table.Td>
          <Table.Td w="30%">
            <Switch disabled={setPracticeClientUserAccessPending || entry.isAccountManager || entry.isCreator} label={entry.canAccess ? 'Yes' : 'No'} checked={entry.canAccess} onChange={(event) => handleCanAccessChange(event.currentTarget.checked, { userId: entry.userId, inviteId: entry.inviteId })} />
          </Table.Td>
          <Table.Td w="30%">
            <Switch disabled={setPracticeClientUserAccessPending} label={entry.isAccountManager ? 'Yes' : 'No'} checked={entry.isAccountManager} onChange={(event) => handleIsAccountManagerChange(event.currentTarget.checked, { userId: entry.userId, inviteId: entry.inviteId })} />
          </Table.Td>
        </Table.Tr>
      );

    });


  }

  return (
    <Box mih="200px">
      {clientPending || practiceUsersPending || practiceUserInvitesIsPending ? (
        <>
          <Center mt="xl">
            <Loader />
          </Center>
        </>) : (!practiceUsers || practiceUsersError || practiceUsersInvitesIsError || !client || clientError) ? (
          <Center>
            <UnableToLoadAlert />
          </Center>
        ) : (
        <ScrollArea type="auto">
          <Table verticalSpacing="md">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Colleague</Table.Th>
                <Table.Th>Access</Table.Th>
                <Table.Th>Account Manager</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {getRows(practiceUsers, practiceUserInvites, client)}
            </Table.Tbody>
          </Table>
        </ScrollArea>
      )}
    </Box>
  );
}