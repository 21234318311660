import React, { useContext } from 'react';
import { UnstyledButton, Group, Avatar, Text, Box, rem } from '@mantine/core';
import { UserContext } from '../userContext';

import classes from './User.module.css';
import { getInitials } from '../lib/getInitials';
import { getAvatarColorFromName } from '../lib/getAvatarColorFromName';
import { IconChevronRight } from '@tabler/icons-react';
import { useMatches, useNavigate } from 'react-router-dom';

export function User() {
  const { user, activeClient } = useContext(UserContext);

  const practiceMode = user?.practiceId && !activeClient;
  
  const navigate = useNavigate();

  const routeMatches = useMatches();
  const active = routeMatches.some(rm => rm.pathname === '/user-settings');

  return (
    <Box
      className={classes.box}
    >
      {user ? (

        <UnstyledButton className={classes.user} data-active={active || undefined} p={rem(5)} onClick={() => !practiceMode && navigate('user-settings')}>
          <Group>
            <Avatar
              color={getAvatarColorFromName(user.lastName)}       
              radius="xl"
            >{getInitials(`${user.firstName} ${user.lastName}`)}</Avatar>

            <div style={{ flex: 1 }}>
              <Text size="sm" fw={500}>
                {user.firstName} {user.lastName}
              </Text>

              <Text c="dimmed" size="xs">
                {user.email}
              </Text>
            </div>

            {!practiceMode && (
              <IconChevronRight style={{ width: rem(14), height: rem(14) }} stroke={1.5} />
            )}
          </Group>
        </UnstyledButton>

      ) : null}
    </Box>
  );
}