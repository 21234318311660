import { Button, ButtonVariant, DefaultMantineColor, MantineSize } from "@mantine/core";
import { PropsWithChildren } from "react";
import { apiClient } from "../api/apiClient";

interface ConnectXeroButtonProps {
  size?: MantineSize;
  className?: string;
  hiddenFrom?: MantineSize;
  visibleFrom?: MantineSize;
  variant?: ButtonVariant;
  leftSection?: React.ReactNode;
  type: 'client' | 'practice-client';
  practiceId?: string;
  clientId?: string;
  disabled?: boolean;
  color?: DefaultMantineColor;
  reconnectXeroTenantId?: string;
}

export const ConnectXeroButton = ({ size, children, className, hiddenFrom, visibleFrom, variant, leftSection, type, practiceId, clientId, disabled, color, reconnectXeroTenantId }: PropsWithChildren<ConnectXeroButtonProps>) => {
  
  if (type === 'practice-client' && !(practiceId && clientId)) {
    throw new Error('practiceId and clientId are required for type practice-client');
  }

  return (
    <Button
      component='a'
      href={type === 'practice-client' ? apiClient.xeroPracticeClientConsentUrl(practiceId!, clientId!, reconnectXeroTenantId) : apiClient.xeroClientConsentUrl}
      size={size}
      className={className}
      hiddenFrom={hiddenFrom}
      visibleFrom={visibleFrom}
      variant={variant}
      leftSection={leftSection}
      disabled={disabled}
      color={color}
    >
      {children}
    </Button>
  );
}